<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<!-- info -->
		<v-sheet class="transparent">
			<!-- SpecialtyName CourseName -->
			<div class="text-h5 font-weight-bold mb-3">
				الصف:
				{{ test.SpecialtyName }}
				- الشعبة:
				{{ test.CourseName }}
			</div>
			

			<div class="text-subtitle-2 text--secondary mb-1">
				المادة: {{ test.SubjectName }}
			</div>
			<!-- teacher -->
			<div class="text-subtitle-2 text--secondary mb-1">
				الاستاذ: {{ test.TeacherName }}
			</div>

			<!-- date -->
			<div class="d-flex justify-space-between">
				<div class="text-subtitle-2 text--secondary ali">
					تاريخ: {{ moment(test.Date).format('YYYY/MM/DD') }}
				</div>
				<v-btn v-if="newVersion" @click="save()" color="myGreen white--text" :loading="loading">
					حفظ
				</v-btn>
			</div>
		</v-sheet>

		<!-- grades -->
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-3">
			<v-data-table
				:headers="gradesHeaders"
				:items="arrayTable"
				item-key="StudentId"
				dense
			>	
				<template v-slot:item.Student="{ item }">
					{{ item.Student.Account.FullName }}
				</template>

				<!-- <template v-slot:item.Test="{ item }">
					<span v-if="item.Test">
						{{ item.Test.MaxGrade }}
					</span>
				</template> -->

				<!-- <template v-slot:item.MaxMark="{ item }">
					{{ item.MaxMark  }}
				</template> -->
				<!-- attendance -->
				<template v-slot:item.IsAbsent="{ item }">
					<v-checkbox
						v-model="item.IsAbsent"
						:true-value="true"
						:false-value="false"
						@change="item.IsAbsent === true ? item.Mark = 0 : null"
					></v-checkbox>
				</template>

				<template v-slot:item.Mark="{ item }">
					<v-text-field
						v-model.number="item.Mark"
						:rules="[val => val <= item.MaxMark || 'يجب أن تكون أصغر من العلامة العظمى']"
						type="number"
						hide-spin-buttons
					/>
				</template>
	
				<!-- attendance -->
				<template v-slot:item.Reason="{ item }">
					<v-text-field
						v-model="item.Reason"
						:disabled="!item.IsAbsent"
					/>
				</template>
				
				<template v-slot:item.Reasoner="{ item }">
					<v-text-field
						v-model="item.Reasoner"
						:disabled="!item.IsAbsent"
					/>
				</template>

				<template v-slot:item.Notes="{ item }">
					<v-text-field
						v-model="item.Notes"
					/>
				</template>

				<!-- expand -->
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<div class="px-2 py-4">
							التبرير:
							{{
								item.Reason
									? item.Reason + ' - ' + item.Reasoner
									: 'لا يوجد'
							}}
						</div>
					</td>
				</template>

				<!-- actions template -->
				<template v-slot:item.action="{ item }">
					<div class="actions">						
						<v-btn
							:to="{
								name: 'student',
								params: { id: item.Id },
							}"
							exact
							title="الملف الشخصي للطالب"
							class="actions-btn myBlueBg"
							depressed>
							<v-icon class="myBlue--text"> mdi-account </v-icon>
						</v-btn>
					</div>
				</template>
				
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { newVersion } from '@/global';

export default {
	name: 'Grades',

	data() {
		return {
			loading: true,

			test: [],
			gradesExpanded: [],
			tableInputs: {},
			show: null,
			arrayTable: [],
			newVersion
		};
	},

	computed: {
		...mapState({
			studentInCourse: state => state.managerCourses.studentInCourse
		}), 
		gradesHeaders() {
			const gradesHeaders = [
				{
					text: 'اسم الطالب',
					value: 'Student',
					// studentName
					showInMobile: true,
					sortable: false,
					class: 'text-center',
					width: '10%'
				},
				// ///////////////////
				{
					text: 'العلامة العظمى',
					value: 'MaxMark',
					name: 'MaxMark',
					showInMobile: true,
					sortable: false,
					width: '10%'
				},
				// ///////////////////
				{
					text: 'العلامة',
					value: 'Mark',
					showInMobile: true,
					sortable: false,
					width: '10%'
				},
				{
					text: 'غائب',
					value: 'IsAbsent',
					name: 'IsAbsent',
					showInMobile: true,
					sortable: false,
					width: 'auto'
				},
				{
					text: 'التبرير',
					value: 'Reason',
					sortable: false,
					width: 'auto'
				},

				{
					text: 'المبرر',
					value: 'Reasoner',
					sortable: false,
					width: 'auto'
				},
				{
					text: 'الملاحظات',
					value: 'Notes',
					sortable: false,
					width: 'auto'
				},
				{
					text: '',
					name: 'action',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
					width: 'auto'
				},
			];
			if (this.$vuetify.breakpoint.mobile)
				return gradesHeaders.filter((e) => e.showInMobile);
			return gradesHeaders;
		},


		gradesHeadersInReadOnly() {
			const gradesHeaders = [
				{
					text: 'اسم الطالب',
					value: 'studentName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'الحضور',
					value: 'attendance',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التبرير-المبرر',
					value: 'Reason',
					sortable: false,
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile)
				return gradesHeaders.filter((e) => e.showInMobile);
			return gradesHeaders;
		},
	},	

	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('tests/fetchById', {
					testId: this.$route.params.id,
				})
				.then((res) => {
					this.test = res;
					if(this.test.Grades?.length === 0) {
						this.arrayTable = []
						this.arrayTable = this.studentInCourse.map(c => ({
							...c,
							MaxMark: this.test.MaxMark
						}))
					} else {
						this.arrayTable = []
						this.arrayTable = this.test.Grades.map(c => ({
							...c,
							MaxMark: this.test.MaxMark
						}))

						// console.log(this.test.Grades.length)
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// style method
		showGradesExpander(item, event) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.gradesExpanded.findIndex(
					(i) => i === item,
				);
				this.gradesExpanded.splice(indexExpanded, 1);
			} else {
				this.gradesExpanded.push(item);
			}
		},

		getAll() {
			this.$store.dispatch('managerCourses/fetchStudentGrades', { 
					courseId: this.$route.query.courseId,
					testId: this.$route.params.id
				}).then((data) => {
					this.arrayTable = []
					this.arrayTable = data.Grades.map(c => ({
						...c,
						MaxMark: data.MaxMark,
						Mark: c.HasValueBefore ? c.Mark : null
					}))
				})
		},

		save() {
			this.loading = true
			this.$store.dispatch('managerCourses/createOrUpdateMark', { 
				studentsMarksRequest: this.arrayTable.map(c => c.Mark !== null && c.Mark !== ''  ? ({
					StudentId: c.Student.Id,
					Mark: c.Mark,
					Notes: c.Notes,
					TestId: Number(this.$route.params.id),
					IsAbsent: c.IsAbsent,
					Reason: c.Reason,
					Reasoner: c.Reasoner,
					Id: c.Id
				}) : null).filter(c => c !== null), 
				testId: this.$route.params.id, 
				courseId: this.$route.query.courseId,
			}).then(() => {
				this.getAll()
			}).finally(() => {
				this.loading = false
			})
		},
		
		moment,
	},

	mounted() {
		if(this.newVersion) {
			this.loading = true
			this.$store.dispatch('managerCourses/fetchStudentGrades', { 
				courseId: this.$route.query.courseId,
				testId: this.$route.params.id
			}).then((data) => {
				this.test = data
				this.arrayTable = []
				this.arrayTable = data.Grades.map(c => ({
					...c,
					MaxMark: data.MaxMark,
					Mark: c.HasValueBefore ? c.Mark : null
				}))
			}).finally(() => {
				this.loading = false
			})
		} else {
			this.fetch()
		}
	},

	metaInfo: {
		title: 'Grades',
		titleTemplate: '%s | Edunix',
	},
};
</script>
