import * as types from '../mutations';
export default {
	namespaced: true,

    state: {},

    actions: {
        createOrUpdateMark ({ commit }, { centerId, userName, studentsMarksRequest }) {
            return window.axios.post(`/center/${centerId}/manager/${userName}/add-update-marks`, { studentsMarksRequest }).then(({ data }) => {
                return data
            })
        }
    }


}