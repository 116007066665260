import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        students: [],
    },

    actions: {
        fetchMarks(
            { rootState },
            { studentId, startDate = null, endDate = null },
        ) {
            return window.axios
                .get(rootState.auth.url + '/student-marks', {
                    params: { studentId, startDate, endDate },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchAllStudents({ rootState, commit, state }) {
            return state.students.length
                ? state.students
                : window.axios
                    .get(rootState.auth.url + '/get-students', {})
                    .then((res) => {
                        commit(types.ALL_STUDENTS, res.data);
                        return res.data;
                    });
        },
        fetchFunds({ rootState }, { id, isStudent, startDate, endDate }) {
            return window.axios
                .get(rootState.auth.url + '/account-checkup', {
                    params: { id, isStudent, startDate, endDate },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchAttendance({ rootState }, { day }) {
            return window.axios
                .get(rootState.auth.url + '/attendance', {
                    params: { day },
                })
                .then((res) => {
                    return res.data;
                });
        }
    },

    mutations: {
        [types.ALL_STUDENTS](state, students) {
            state.students = students;
        },
    },
};
