import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		specialties: [],
	},

	actions: {
		fetchAll({ commit, rootState, state }) {
			return state.specialties.length
				? state.specialties
				: window.axios.get(rootState.auth.url + '/get-specialties').then((res) => {
						commit(types.SPECIALTIES, res.data);
						return res.data;
				  });
		},
	},

	mutations: {
		[types.SPECIALTIES](state, specialties) {
			state.specialties = specialties;
		},
	},

	getters: {
		getSpecialtyByCourseId: (state) => (id) =>
			state.specialties.find((e) => e.Courses.find((c) => c.Id === id)),
		getCourseByCourseId: (state) => (id) =>
			state.specialties
				.map((e) => e.Courses)
				.flat()
				.find((e) => e.Id === id),
	},
};
