import * as types from '../mutations';

export default {
	namespaced: true,
    state: {
        studentInCourse: []
    },

    actions: {
        createOrUpdateBehavioralNotes({ commit, rootState }, { info, courseId }) {
            return window.axios.post(rootState.auth.url + '/add-update-behavioral-notes', info, { params: { courseId } }).then(({ data }) => {
                return data
            })
        },

        deleteBehavioralNotes({ commit, rootState }, { noteId, courseId }) {
            return window.axios.post(rootState.auth.url + '/delete-behavioral-note', {}, { params: { noteId, courseId } }).then(({ data }) => {
                return data
            })
        },
        createOrUpdateManagerNotes({ commit, rootState }, { info }) {
            return window.axios.post(rootState.auth.url + '/add-update-manager-notes', info).then(({ data }) => {
                return data
            })
        },
        deleteManagerNotes ({ commit, rootState }, { noteId }) {
            return window.axios.post(rootState.auth.url + '/delete-Manager-note', {}, { params: { noteId } }).then(({ data }) => {
                return data
            })
        },

        fetchNoteType({ commit, rootState}) {
            return window.axios.get(rootState.auth.url + '/get-note-types').then(({ data }) => {
                return data
            })
        },

        // createOrUpdateMark({ commit, rootState }) {
        //     return window.axios.get(rootState.auth.url + '/add-update-marks').then(({ data }) => {
        //         return data
        //     })
        // },

        createOrUpdateMark({ commit, rootState }, { studentsMarksRequest, courseId, testId }) {
            return window.axios.post(rootState.auth.url + '/add-update-marks', studentsMarksRequest, { 
                params: { courseId, testId } 
            }).then(({ data }) => {
                return data
            })
        },

        fetchStudentByCourse({ commit, rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url + '/get-course-students', { params: { courseId } }).then(({ data }) => {
                commit(types.STUDENT_IN_COURSE, data)
                return data
            })
        },

        createAttendances({ commit, rootState }, { attendanceRequests, courseId, isTeachers }) {
            return window.axios.post(rootState.auth.url + '/add-update-attendances', 
                attendanceRequests, { 
                    params: {
                        isTeachers,
                        courseId
                    } 
                }).then(({ data }) => {
                return data
            })
        },

        // new endpoint get attendances 
        fetchAttendances ({ commit, rootState }, { courseId, day }) {
            return window.axios.get(rootState.auth.url +'/courseAttendance', { params: { courseId, day } })
            .then(({ data }) => {
                return data
            })
        },
        // new endpoint get test student grades
        fetchStudentGrades({ commit, rootState }, { courseId, testId }) {
            return window.axios.get(rootState.auth.url+'/get-test-AllStudents-grades', { 
                params: {
                    courseId,
                    testId
                } 
            }).then(({ data }) => {
                return data
            })     
        },

        // new endpoint get attendances for teachers
        fetchAttendancesTeacher({ commit, rootState }, { day }) {
            return window.axios.get(rootState.auth.url+'/TeachersAttendance', { params: { day } }).then(({ data }) => {
                return data
            })
        },

        // new endpoint to get staff
        fetchStaff({ commit, rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url+'/GetStaffs', { params: { courseId }}).then(({ data }) => {
                return data
            })
        }
    },

    mutations: {
        [types.STUDENT_IN_COURSE] (state, studentInCourse) {
            state.studentInCourse = studentInCourse
        }
    }
    
}