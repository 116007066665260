<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<!-- info -->
		<v-sheet class="transparent">
			<!-- SpecialtyName CourseName -->
			<div class="text-h5 font-weight-bold mb-3">
				الصف:
				{{ test.SpecialtyName }}
				- الشعبة:
				{{ test.CourseName }}
			</div>

			<!-- teacher -->
			<div class="text-subtitle-2 text--secondary mb-1">
				الاستاذ: {{ test.TeacherName }}
			</div>

			<!-- date -->
			<div class="text-subtitle-2 text--secondary">
				تاريخ: {{ moment(test.Date).format('YYYY/MM/DD') }}
			</div>
		</v-sheet>

		<!-- grades -->
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
			<v-data-table
				:headers="gradesHeaders"
				:items="test.Grades"
				item-key="Id"
				@click:row="showGradesExpander"
				:expanded.sync="gradesExpanded"
				:show-expand="$vuetify.breakpoint.mobile"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 210px)"
				fixed-header
				mobile-breakpoint="0">
				<!-- name -->
				<template v-slot:item.studentName="{ item }">
					{{ item.Student.Account.FirstName }}
					{{ item.Student.Account.LastName }}
				</template>
				<!-- mark -->
				<template v-slot:item.mark="{ item }">
					<template v-if="item.IsAbsent"> لم يقدم </template>
					<template v-else>
						<span class="font-weight-bold">
							<span
								:class="
									item.Mark >= item.Test.MinGrade
										? 'myGreen--text'
										: 'red--text'
								">
								{{ item.Mark }} </span
							>\{{ item.Test.MaxGrade }}
						</span>
					</template>
				</template>
				<!-- attendance -->
				<template v-slot:item.attendance="{ item }">
					<v-icon
						:class="!item.IsAbsent ? 'myGreen--text' : 'red--text'">
						{{ !item.IsAbsent ? 'mdi-check' : 'mdi-close' }}
					</v-icon>
				</template>
				<!-- justify -->
				<template v-slot:item.Reason="{ item }">
					{{ item.Reason | isAvailable }}
					{{ item.Reasoner && ' - ' }}
					{{ item.Reasoner }}
				</template>
				<!-- expand -->
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<div class="px-2 py-4">
							التبرير:
							{{
								item.Reason
									? item.Reason + ' - ' + item.Reasoner
									: 'لا يوجد'
							}}
						</div>
					</td>
				</template>
				<!-- actions template -->
				<template v-slot:item.action="{ item }">
					<div class="actions">
						<v-btn
							:to="{
								name: 'student',
								params: { id: item.Student.Id },
							}"
							exact
							title="الملف الشخصي للطالب"
							class="actions-btn myBlueBg"
							depressed>
							<v-icon class="myBlue--text"> mdi-account </v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'Grades',

	data() {
		return {
			loading: true,

			test: [],
			gradesExpanded: [],
		};
	},

	computed: {
		gradesHeaders() {
			const gradesHeaders = [
				{
					text: 'اسم الطالب',
					value: 'studentName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'الحضور',
					value: 'attendance',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التبرير-المبرر',
					value: 'Reason',
					sortable: false,
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile)
				return gradesHeaders.filter((e) => e.showInMobile);
			return gradesHeaders;
		},
	},

	methods: {
		fetch() {
			this.loading = true;
			this.$store
				.dispatch('tests/fetchById', {
					testId: this.$route.params.id,
				})
				.then((res) => {
					this.test = res;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// style method
		showGradesExpander(item, event) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.gradesExpanded.findIndex(
					(i) => i === item,
				);
				this.gradesExpanded.splice(indexExpanded, 1);
			} else {
				this.gradesExpanded.push(item);
			}
		},
		moment,
	},

	mounted() {
		this.fetch();
	},

	metaInfo: {
		title: 'Grades',
		titleTemplate: '%s | Edunix',
	},
};
</script>
