<template>
	<div class="messages d-flex flex-column-reverse align-center">
		<div style="width: 100%">
			<div class="d-flex flex-column">
				<div
					dense
					color="transparent"
					class="d-flex flex-column">
					<div
						v-for="(message, i) in messages"
						:key="i"
						class="px-0 mb-2">
						<message :message="message"></message>
					</div>
				</div>
				<div
					height="150"
					class="px-2 white text-area message-input">
					<div
						class="d-flex pt-2"
						style="width: 100%">
						<div class="ml-2 flex-grow-1">
							<v-textarea
								v-model="message"
								label="الرسالة"
								outlined
								dense
								hide-details
								:rows="3"
								auto-grow
								no-resize
								class="rounded-lg"></v-textarea>
							<v-checkbox
								v-if="false"
								dense
								hide-details
								label="مرسل من قبل ولي الأمر"
								v-model="isParent"></v-checkbox>
						</div>
						<div>
							<v-btn
								:loading="loading"
								:disabled="loading"
								@click="sendMessage"
								class="py-4"
								color="primary">
								<v-icon class="send-icon text-h5">
									mdi-send-variant
								</v-icon>
							</v-btn>
						</div>
					</div>

					<p
						class="mb-2 mt-3 px-2"
						v-if="false">
						يتم الرد على الرسائل خلال يومي عمل ضمن أوقات الدوام
						الرسمي
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Message from '@/components/Message.vue';

export default {
	name: 'Chat',

	components: { Message },

	props: ['messages', 'student'],

	data() {
		return {
			message: null,
			isParent: false,

			loading: false,
		};
	},

	methods: {
		sendMessage() {
			this.loading = true;
			this.$store
				.dispatch('student/sendMessage', {
					message: this.message,
					StudentId: this.student.StudentId,
					StudentUserName: this.student.StudentUserName,
					CourseName: this.student.CourseName,
				})
				.then(() => {
					this.message = null;
					this.$emit('update');
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.send-icon {
	transform: scale(-1);
}
.messages {
	padding-top: 10px;
	height: calc(100vh - 180px);
	overflow-y: scroll;
}
.message-input {
	position: sticky;
	bottom: 0;
}
</style>
