<template>
	<v-navigation-drawer
		width="180"
		app
		right
		class="primary white--text"
		v-model="localValue"
		:mini-variant="miniWithMobile">
		<v-list-item
			@click="mini = !mini"
			:to="{ name: 'home' }"
			exact
			title="الرئيسية">
			<v-list-item-icon v-if="!$vuetify.breakpoint.mobile">
				<v-icon color="white">mdi-menu</v-icon>
			</v-list-item-icon>
			<v-list-item-content class="white--text">
				<span
					style="font-size: 0.8rem !important"
					v-if="centers.length > 1">
					{{ getCenter }}
				</span>
			</v-list-item-content>
		</v-list-item>

		<!-- nav bar -->
		<v-list>
			<!-- home -->
			<v-list-item
				:to="{ name: 'home' }"
				exact
				title="الرئيسية">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-home</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> الرئيسية </v-list-item-content>
			</v-list-item>
			<!-- students -->
			<v-list-item
				v-if="permissions.Students"
				:to="{ name: 'allStudents' }"
				title="الطلاب">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-school</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> الطلاب </v-list-item-content>
			</v-list-item>
			<!-- teachers -->
			<v-list-item
				v-if="permissions.Teachers"
				:to="{ name: 'allTeachers' }"
				title="المدرسون">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-human-male-board</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> المدرسون </v-list-item-content>
			</v-list-item>
			<!-- courses -->
			<v-list-item
				v-if="permissions.Courses"
				:to="{ name: 'allCourses' }"
				title="الشعب">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-bookshelf</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> الشعب </v-list-item-content>
			</v-list-item>

			<v-divider
				class="white mx-auto my-2"
				style="width: 90%"></v-divider>

			<!-- schedule -->
			<v-list-item
				v-if="permissions.Schedual"
				@click="$emit('openDialog', 'schedule')"
				title="برنامج الدوام">
				<v-list-item-icon>
					<v-icon class="white--text"> mdi-calendar-blank-multiple </v-icon>
				</v-list-item-icon>
				<v-list-item-content
					class="white--text text-body-2"
					style="white-space: nowrap">
					برنامج الدوام
				</v-list-item-content>
			</v-list-item>

			<!-- marks -->
			<v-list-item
				v-if="permissions.Marks"
				@click="$emit('openDialog', 'marks')"
				title="العلامات">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-note-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> العلامات </v-list-item-content>
			</v-list-item>

			<!-- funds -->
			<v-list-item
				v-if="permissions.Funds"
				@click="$emit('openDialog', 'funds')"
				title="الدفعات">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-cash-multiple</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> الدفعات </v-list-item-content>
			</v-list-item>

			<!-- attendance -->
			<v-list-item
				v-if="permissions.Attendence"
				@click="$emit('openDialog', 'attendance')"
				title="الحضور">
				<v-list-item-icon>
					<v-icon class="white--text">mdi-calendar-check-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="white--text"> الحضور </v-list-item-content>
			</v-list-item>
		</v-list>

		<div slot="append">
			<div class="append text-center pb-2">
				<div>v {{ VERSION }}</div>
				<div>From Edunix family</div>
				<div>
					Powered by
					<a
						href="https://beetronix.com"
						class="myOrange--text"
						>BEETRONIX</a
					>
				</div>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import { VERSION } from '@/helpers/constants';
import { mapState, mapGetters } from 'vuex';
export default {
	name: 'NavigationDrawer',
	props: { value: { typeof: Boolean } },
	data() {
		return {
			mini: false,
			VERSION,
		};
	},
	computed: {
		miniWithMobile: {
			get() {
				return !this.$vuetify.breakpoint.mobile && this.mini;
			},
			set(newMini) {
				this.mini = !this.$vuetify.breakpoint.mobile && newMini;
			},
		},

		localValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			},
		},
		...mapState({
			centers: (state) => state.auth.centers,
			permissions: (state) => state.auth.permissions,
		}),
		...mapGetters({
			getCenter: 'auth/getCenter',
		}),
	},
};
</script>

<style scoped>
.append * {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 0.7rem;
}
.append *:last-child {
	font-size: 0.6rem;
}
</style>
