<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<div v-else>
		<!-- tabs -->
		<div class="d-flex flex-wrap justify-start align-center pt-3">
			<div
				@click="tab = 'teachers'"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'teachers' }">
				المدرسين
			</div>
			<div
				@click="
					tab = 'attendanceTeachers';
					getAttendance()
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'attendanceTeachers' }">
				حضور المدرسين
			</div>
			
		</div>
		<v-divider></v-divider>

		<v-tabs-items
			v-model="tab"
			class="transparent"
			touchless
		>
			<!-- teachers -->
			<v-tab-item
				value="teachers"
				class="pb-1"
			>
				<!-- filter form -->
				<v-row
					class="ma-0"
					dense>
					<v-col
						cols="12"
						sm="6"
						md="5"
						lg="3">
						<v-text-field
							v-model="teacherName"
							label="اسم المدرس"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>
					<v-col
						cols="12"
						sm="6"
						md="5"
						lg="3">
						<v-autocomplete
							v-model="degreeId"
							:items="degrees"
							item-value="Id"
							item-text="Name"
							label="الاختصاص"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg">
						</v-autocomplete>
					</v-col>
					<v-col cols="auto">
						<v-checkbox
							dense
							hide-details
							v-model="onlyUnDoneTest"
							label="العلامات غير المسلمة فقط"></v-checkbox>
					</v-col>
					<v-col
						cols="12"
						sm="auto"
						class="mr-0 mr-sm-3">
						<v-btn
							:disabled="loadingTeacher"
							:loading="loadingTeacher"
							color="primary"
							@click="filterTeachers">
							بحث
						</v-btn>
					</v-col>
				</v-row>
				<v-divider></v-divider>
				<!-- main -->
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="header"
						:items="teacherArr || teachers"
						:loading="loadingTeacher"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 170px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- name template -->
						<template v-slot:item.Teacher="{ item }">
							{{ item.Teacher.Accounts.FirstName }}
							{{ item.Teacher.Accounts.LastName }}
						</template>
						<!-- subject template -->
						<template v-slot:item.Degree="{ item }">
							{{ item.Degree.Name }}
						</template>
						<!-- actions template -->
						<template v-slot:item.action="{ item }">
							<div class="actions mx-auto">
								<v-btn
									:to="{
										name: 'teacher',
										params: { id: item.Teacher.Id },
									}"
									title="الملف الشخصي للمدرس"
									depressed
									class="actions-btn myBlueBg">
									<v-icon class="myBlue--text"> mdi-account </v-icon>
								</v-btn>
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>

			<!-- attendanceTeachers -->
			<v-tab-item
			value="attendanceTeachers"
			class="pb-1"
			>
				<!-- filter -->
				<v-row class="">
					<v-col cols="3" align-self="center" class="mt-1">
						<bee-date-picker
							v-model="startDateAttendance"
							input-label="Date"
							elevation="0"
							menu-picker
							dense
							hide-details
							:input-props="{
								outlined: true,
								dense: true
							}"
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1940-01-01"
							:disabled="loadingAttendance"
							:loading="loading"
							@change="getAttendance()"
						></bee-date-picker>
					</v-col>


					<v-col cols="auto" align-self="center" class="mt-1">
						<v-btn 
							@click="submitAttendance()"
							color="myGreen white--text" 
							:loading="loadingAttendance"
							:disabled="attendance.map(c => c.type).filter(c => c === 0 || c === null).length === attendance.length"
						>
							حفظ
						</v-btn>
					</v-col>
				</v-row>

				<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
					<div>
						<v-checkbox
							v-if="notFoundAtten" 
							v-model="all"
							label="الكل"
							@click="attendance.map(c => c.type === 1 ? c.type = 0 : c.type = 1)" 
							hide-details
						></v-checkbox>
						<v-data-table
							:headers="attendanceHeader"
							:items="attendance"
							dense
							:items-per-page="-1"
							height="calc(100vh - 290px)"
							fixed-header
							mobile-breakpoint="0"
							:loading="loadingAttendance"
							>
	
							<template v-slot:item.type="{ item }">
								<v-radio-group
									v-model="item.type"
									row
									hide-details
									class="mt-0"
								>
									<v-radio
										label="موجود"
										:value="1"
									></v-radio>
	
									<v-radio
										label="غائب"
										:value="2"
									></v-radio>
	
									<v-radio
										label="متأخر"
										:value="3"
									></v-radio>
									<v-radio
										label="انصراف مبكر"
										:value="4"
									></v-radio>
								</v-radio-group>
							</template>
							
							<template v-slot:item.EarlyLeaveMinutes="{ item }">
								<v-text-field
									v-model="item.EarlyLeaveMinutes"
									:disabled="item.type !== 4"
								 />
							</template>
	
							<template v-slot:item.LateMinutes="{ item }">
								<v-text-field
									v-model="item.LateMinutes"
									:disabled="item.type !== 3"
								 />
							</template>
	
							<template v-slot:item.Justification="{ item }">
								<v-text-field
									v-model="item.Justification"
									:disabled="item.type !== 2 && item.type !== 3 && item.type !== 4"
								 />
							</template>
	
							<template v-slot:item.ParentJustification="{ item }">
								<v-text-field
									v-model="item.ParentJustification"
									:disabled="item.type !== 2 && item.type !== 3 && item.type !== 4"
								 />
							</template>
							
							<!-- actions -->
							<template v-slot:item.action="{ item }">
								<div class="actions">
									<v-btn
										:to="{
											name: 'student',
											params: {
												id: item.Student.Id,
											},
										}"
										title="الملف الشخصي للطالب"
										exact
										depressed
										class="actions-btn myBlueBg"
									>
										<v-icon class="myBlue--text"> mdi-account </v-icon>
									</v-btn>
								</div>
							</template>
						</v-data-table>
					</div>
				</v-card>
			</v-tab-item>

		</v-tabs-items>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	name: 'AllTeachers',

	data() {
		return {
			loadingAttendance: false,
			loading: true,
			loadingTeacher: false,
			degreeId: null,
			teacherName: null,
			onlyUnDoneTest: false,

			teacherArr: null,

			header: [
				{
					text: 'اسم المدرس',
					value: 'Teacher',
					align: 'start',
					sortable: false,
				},
				{
					text: 'الاختصاص',
					value: 'Degree',
					align: 'start',
					sortable: false,
				},
				{
					text: 'علامات غير مسلمة',
					value: 'UnDoneTest',
					align: 'start',
					width: '20%',
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					width: '10%',
				},
			],
			tab: 'teachers',
			attendance: [],
			startDateAttendance: this.moment().format('YYYY-MM-DD'),
			teachersItem: [],
			all: null
			// teacherArr: []
		};
	},

	computed: {
		...mapState({
			teachers: (state) => state.teacher.teachers,
			degrees: (state) => state.teacher.degrees,
		}),

		attendanceHeader() {
			return [
				{
					text: 'الاسم',
					value: 'name',
					align: 'center',
					sortable: false
				},
				{
					text: '',
					value: 'type',
					align: 'start',
					sortable: false
				},

				{
					text: ' الانصراف قبل (د)',
					value: 'EarlyLeaveMinutes',
					align: 'center',
					width: '6%',
					sortable: false
				},
				{
					text: 'مدة التأخير (د)',
					value: 'LateMinutes',
					align: 'center',
					width: '6%',
					sortable: false
				},
				{
					text: 'التبرير',
					value: 'Justification',
					align: 'center',
					sortable: false
				},
				{
					text: 'المبرر',
					value: 'ParentJustification',
					align: 'center',
					sortable: false
				}
			]
		},

		notFoundAtten() {
			let atten = false
			if(this.attendance.filter(c => c.HasValueBefore === false).length === this.attendance.length) {
				atten = true
			} else {
				atten = false
			}
			return atten
		}
	},

	methods: {
		moment,
		filterTeachers() {
			this.loadingTeacher = true;
			setTimeout(() => {
				this.loadingTeacher = false;
				this.teacherArr = this.teachers
					.filter((e) => {
						if (!this.degreeId) return true;
						return e.Degree.Id === this.degreeId;
					})
					.filter((e) => {
						if (!this.teacherName) return true;
						return (
							e.Teacher.Accounts.FirstName.includes(
								this.teacherName,
							) ||
							e.Teacher.Accounts.LastName.includes(
								this.teacherName,
							)
						);
					})
					.filter((e) =>
						this.onlyUnDoneTest ? e.UnDoneTest !== '-' : true,
					);
			}, 500);
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('teacher/fetchAll').then((data) => {
					this.teachersItem = data
					console.log(this.teachersItem)
				}),
				this.$store.dispatch('teacher/fetchDegrees'),
			]).finally(() => {
				this.loading = false;
			});
		},
		// fetchAttendanceTeachers () {
		// 	this.attendance = this.teachers
		// }

		getAttendance() {
			this.all = null
			this.loadingAttendance = true
			this.$store.dispatch('managerCourses/fetchAttendancesTeacher', {
					day: new Date(
						new Date(this.startDateAttendance).setHours(new Date().getTimezoneOffset() / -60, 0, 0, 0),
					).getTime(),
				}).then((res) => {
					this.attendance = res.TeacherAttendance.map(c => ({ 
						...c, 
						name: c.Account.FullName,
						type: c.HasValueBefore ?
							c.HasAttend ? 
							c.IsLate ? 3 : c.IsEarlyLeave ? 4 : 1 : 
							2
							: null,
						AccountId: c.Account.Id,
						Id: c.Id
					}));
					this.loadingAttendance = false
				}).catch(() => {
					this.loadingAttendance = false
				})
		},

		submitAttendance() {
			this.loadingAttendance = true
			this.$store.dispatch('managerCourses/createAttendances', {
				courseId: 0,
				isTeachers: true,
				attendanceRequests: this.attendance.map(c => c.type !== null && c.type !== '' && c.type !== 0 ? ({
					Id: c.Id,
					AccountId: c.AccountId,
					CourseId: 0,
					Date: this.startDateAttendance,
					HasAttend: c.type !== 2,
					IsLate: c.type === 3,
					IsEarlyLeave: c.type === 4,
					EarlyLeaveMinutes: c.EarlyLeaveMinutes,
					LateMinutes: c.LateMinutes,
					Justification: c.Justification,
					ParentJustification: c.ParentJustification
				}) : null).filter(c => c !== null)
			}).finally(() => {
				this.loadingAttendance = false
			})
		}
	},

	mounted() {
		this.fetch();
	},

	metaInfo: {
		title: 'All teachers',
		titleTemplate: '%s | Edunix',
	},
};
</script>
