import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './lang/index';
import VueApexCharts from 'vue-apexcharts';
import VueMeta from 'vue-meta';
import axios from 'axios';
import { BASE_URL } from './helpers/constants';
import $eventBus from './helpers/eventBus';
import './registerServiceWorker';
import BeeComponents from '@beetronix/bee-components'
import '@beetronix/bee-components/lib/css/index.css';

import moment from 'moment';

moment.locale('ar', {
	months: [
		'كانون الثاني',
		'شباط',
		'آذار',
		'نيسان',
		'أيار',
		'حزيران',
		'تموز',
		'آب',
		'أيلول',
		'تشرين الأول',
		'تشرين الثاني',
		'كانون الأول',
	],
});

Vue.component('apexchart', VueApexCharts);

Vue.use(VueMeta);
Vue.use(BeeComponents)

Vue.filter('isAvailable', (val, val2 = true) => {
	return (val2 && val) || '-';
});

/** axios */
window.axios = axios.create({
	baseURL: BASE_URL,
});

window.axios.defaults.headers.common['accept-language'] = 'ar';

window.axios.interceptors.response.use(
	function (response) {
		return response;
	},

	function (error) {
		if (!window.navigator.onLine) {
			$eventBus.$emit('error', true);
			return Promise.reject(error);
		}
		if (error.response) {
			if (error.response.status === 401) {
				store.dispatch('auth/logout');
				$eventBus.$emit('show-snackbar', true, 'تم تعديل معلومات الحساب');
				if (router.history.current.name !== 'login') router.replace({ name: 'login' });
			} else if (error.response.status === 405) {
				return Promise.reject(error);
			} else {
				$eventBus.$emit('error', false);
			}
		} else if (
			error.code === 'ERR_NETWORK' ||
			error.code === 'ETIMEDOUT' ||
			error.code === 'ECONNABORTED'
		) {
			$eventBus.$emit('error', true);
		}
		return Promise.reject(error);
	},
);
/** end axios */

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
