<template>
	<div class="home">
		<div class="d-flex flex-column justify-center align-center">
			<v-img
				:src="IMAGE_URL"
				width="9rem"></v-img>
			<h1 class="text-h6 overline font-weight-bold text-center">Edunix manager</h1>
		</div>
	</div>
</template>

<script>
import { IMAGE_URL } from '@/helpers/constants';

export default {
	name: 'Home',
	data() {
		return {
			IMAGE_URL,
		};
	},
};
</script>

<style lang="scss" scoped>
.home * {
	font-family: Arial, Helvetica, sans-serif !important;
}
.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 160px);
	@media (max-width: 576px) {
		height: calc(100vh - 220px);
	}
}
.home-edunix-logo {
	height: 100px;
	width: 100px;
}
</style>
