export default {
    namespaced: true,

    actions: {
        fetchById({ rootState }, { testId }) {
            return window.axios.get(rootState.auth.url + '/get-test-grade', {
                params: { testId }
            }).then(res => {
                return res.data
            })
        }
    }
}