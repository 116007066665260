import * as types from "../mutations"

export default {
    namespaced: true,

    state: {

    },

    actions: {
        fetchStudents({ rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url + '/get-course-students', {
                params: { courseId }
            }).then((res) => {
                return res.data
            })
        },
        fetchTests({ rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url + '/get-course-tests', {
                params: { courseId }
            }).then((res) => {
                return res.data
            })
        },
        fetchSchedule({ rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url + '/get-course-schedule', {
                params: { courseId }
            }).then((res) => {
                return res.data
            })
        },
        fetchSubjects({ rootState }, { courseId }) {
            return window.axios.get(rootState.auth.url + '/get-subjects', {
                params: { courseId }
            }).then((res) => {
                return res.data
            })
        }
    }
}