<template>
	<div>
		<h2 class="primary--text text-h5 mb-2">الدفعات</h2>
		<v-chip-group mandatory>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="
					isStudent = true;
					fetchStudents();
				">
				طلاب
			</v-chip>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="
					isStudent = false;
					fetchTeachers();
				">
				مدرسون
			</v-chip>
		</v-chip-group>
		<v-row
			dense
			align="center">
			<v-col
				cols="12"
				sm="5">
				<v-autocomplete
					v-model="selectedProfileId"
					:items="isStudent ? students : teachers"
					:loading="loading"
					:item-text="
						isStudent ? 'Name' : 'Teacher.Accounts.FullName'
					"
					:item-value="isStudent ? 'Id' : 'Teacher.Id'"
					:label="isStudent ? 'الطالب' : 'المدرس'"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-autocomplete>
			</v-col>
			<v-col
				cols="12"
				sm="5">
				<v-btn
					color="primary"
					block
					outlined
					@click="dateDialog = true">
					<template v-if="dates && dates[0] && dates[1]">
						<strong class="text-caption">{{
							dates[0].replaceAll('-', '/')
						}}</strong>
						-
						<strong class="text-caption">{{
							dates[1].replaceAll('-', '/')
						}}</strong>
					</template>
					<template v-else>
						<v-icon left> mdi-calendar-month-outline </v-icon>
						تاريخ
					</template>
				</v-btn>
			</v-col>
			<v-col
				cols="12"
				sm="2">
				<v-btn
					:disabled="!selectedProfileId"
					color="primary"
					block
					@click="fetchFunds">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<v-divider class="my-3"></v-divider>
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
			<v-data-table
				:headers="financeHeader"
				:items="funds.filter((e) => e.InAmount || e.OutAmount)"
				:loading="loadingFunds"
				item-key="Id"
				@click:row="showFinanceExpander"
				:expanded.sync="financeExpanded"
				:show-expand="$vuetify.breakpoint.mobile"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 320px)"
				fixed-header
				mobile-breakpoint="0">
				<!-- funds template -->
				<template v-slot:item.Date="{ item }">
					{{ moment(item.DateTime).format('YYYY/MM/DD') }}
				</template>
				<!-- funds template -->
				<template v-slot:item.type="{ item }">
					{{ item.FundType?.Name }}
				</template>
				<!-- receipt template -->
				<template v-slot:item.InAmount="{ item }">
					<span class="font-weight-bold">
						{{
							new Intl.NumberFormat().format(item.InAmount)
								| isAvailable(item.InAmount)
						}}
					</span>
				</template>
				<!-- payment template -->
				<template v-slot:item.OutAmount="{ item }">
					<span class="font-weight-bold">
						{{
							new Intl.NumberFormat().format(item.OutAmount)
								| isAvailable(item.OutAmount)
						}}
					</span>
				</template>
				<!-- expanded template -->
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<div class="px-2 py-4">
							<strong v-if="item.Details">
								{{ item.FundType?.Name }}/{{ item.Details }}
								<br />
							</strong>
							<span v-if="item.BookId">
								رقم الدفتر: {{ item.BookId }}
							</span>
							<span v-if="item.ReceiptId">
								| رقم الوصل:
								{{ item.ReceiptId }}
							</span>
						</div>
					</td>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			v-model="dateDialog"
			max-width="300px"
			transition="dialog-transition">
			<v-card class="pa-2">
				<v-date-picker
					locale="ar-SY"
					v-model="dates"
					range></v-date-picker>
				<v-row dense>
					<v-col cols="6">
						<v-btn
							block
							color="primary"
							@click="
								dates = null;
								dateDialog = false;
							">
							إعادة التعين
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							block
							outlined
							color="primary"
							@click="dateDialog = false">
							إلغاء
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'FundsDialog',

	data() {
		return {
			loading: false,
			loadingFunds: false,

			funds: [],

			isStudent: true,
			selectedProfileId: null,
			dates: null,

			dateDialog: false,

			financeExpanded: [],
		};
	},

	watch: {
		dates() {
			if (this.dates && this.dates[1]) this.dateDialog = false;
		},
	},

	computed: {
		financeHeader() {
			const financeHeader = [
				{
					text: this.isStudent ? 'مقبوض' : 'له',
					value: 'InAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: this.isStudent ? 'عليه' : 'مدفوع',
					value: 'OutAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'رقم الدفتر',
					value: 'BookId',
					sortable: false,
				},
				{
					text: 'رقم الوصل',
					value: 'ReceiptId',
					sortable: false,
				},
				{
					text: 'النوع',
					value: 'type',
					sortable: false,
				},
				{
					text: 'تفاصيل',
					value: 'Details',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return financeHeader.filter((e) => e.showInMobile);
			}
			return financeHeader;
		},

		sortedDate() {
			return this.dates
				? new Date(this.dates[0]).getTime() <
				  new Date(this.dates[1]).getTime()
					? [
							new Date(this.dates[0]).getTime(),
							new Date(this.dates[1]).getTime(),
					  ]
					: [
							new Date(this.dates[1]).getTime(),
							new Date(this.dates[0]).getTime(),
					  ]
				: [,];
		},

		...mapState({
			teachers: (state) => state.teacher.teachers,
			students: (state) => state.sideBar.students,
		}),
	},

	methods: {
		fetchFunds() {
			this.funds = [];
			this.loadingFunds = true;
			this.$store
				.dispatch('sideBar/fetchFunds', {
					id: this.selectedProfileId,
					isStudent: this.isStudent,
					startDate: this.sortedDate[0],
					endDate: this.sortedDate[1],
				})
				.then((res) => {
					this.funds = res;
				})
				.finally(() => {
					this.loadingFunds = false;
				});
		},

		fetchStudents() {
			this.funds = [];
			this.selectedProfileId = null;
			this.financeExpanded = [];
			this.loading = true;
			this.$store.dispatch('sideBar/fetchAllStudents').finally(() => {
				this.loading = false;
			});
		},
		fetchTeachers() {
			this.funds = [];
			this.selectedProfileId = null;
			this.financeExpanded = [];
			this.loading = true;
			this.$store.dispatch('teacher/fetchAll').finally(() => {
				this.loading = false;
			});
		},

		showFinanceExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.financeExpanded.findIndex(
					(i) => i === item,
				);
				this.financeExpanded.splice(indexExpanded, 1);
			} else {
				this.financeExpanded.push(item);
			}
		},
		moment,
	},

	mounted() {
		this.fetchStudents();
	},
};
</script>
