<template>
	<v-sheet
		color="transparent"
		class="d-flex"
		:class="{ sent: message.IsFromAdmin }">
		<v-avatar
			class="mx-2 white--text"
			:color="!message.IsParent ? 'primary' : 'secondary'"
			:size="$vuetify.breakpoint.mobile ? 30 : 40">
			<img
				:src="IMAGE_URL"
				alt=""
				v-if="message.IsFromAdmin" />
			<span v-else-if="message.IsParent">
				<v-icon color="white">mdi-account-supervisor</v-icon>
			</span>
			<span v-else>
				{{ message.UserName.slice(0, 1) }}
			</span>
		</v-avatar>
		<v-card
			class="message-text pa-3 pb-1 rounded-lg"
			:class="!message.IsFromAdmin ? 'white' : 'primary lighten-4'">
			<div>
				{{ message.Content }}
			</div>
			<v-divider class="mt-2 mb-1"></v-divider>
			<div class="d-flex align-center text-caption text--secondary">
				{{ moment(message.SentAt).format('YYYY/MM/DD') }}
			</div>
		</v-card>
	</v-sheet>
</template>

<script>
import moment from 'moment';
import { IMAGE_URL } from '@/helpers/constants';

export default {
	name: 'Message',

	props: ['message'],

	data() {
		return {
			IMAGE_URL,
		};
	},

	methods: {
		moment,
	},
};
</script>

<style lang="scss" scoped>
.sent {
	flex-direction: row-reverse;
}
.message-text {
	width: 70%;
}
</style>
