<template>
	<div class="cart">
		<apexchart
			v-if="series[0].data.length"
			class="apexchart"
			type="rangeBar"
			height="450"
			:options="options"
			:series="series"></apexchart>
		<v-dialog
			v-model="showDialog"
			width="300">
			<v-card class="pa-4">
				<div v-html="dialogText"></div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

const weekNames = [
	'الجمعة',
	'السبت',
	'الأحد',
	'الأثنين',
	'الثلاثاء',
	'الأربعاء',
	'الخميس',
];
export default {
	name: 'WorkingHours',
	components: { VueApexCharts },
	props: ['data'],
	data() {
		return {
			showDialog: false,
			dialogText: null,
		};
	},
	computed: {
		options() {
			return {
				// colors: ['#fb5d33', '#2cac3e', '#5da2ff'],
				colors: ['#fb5d33'],
				tooltip: {
					enabled: !this.$vuetify.breakpoint.mobile,
					custom: function ({
						series,
						seriesIndex,
						dataPointIndex,
						w,
					}) {
						var data =
							w.globals.initialSeries[seriesIndex].data[
								dataPointIndex
							];

						let mes = "<ul class='pa-4 schedule-list'>";
						if (data.SubjectName)
							mes += '<li><b>المادة: </b>' + data.SubjectName;
						if (data.TeacherName)
							mes += '<li><b>المدرس: </b>' + data.TeacherName;
						if (data.RoomName)
							mes += '<li><b>القاعة: </b>' + data.RoomName;
						if (data.SpecialtyName)
							mes += '<li><b>الصف: </b>' + data.SpecialtyName;
						if (data.CourseName)
							mes += '<li><b>الشعبة: </b>' + data.CourseName;
						mes += '</ul>';

						return mes;
					},
				},
				grid: {
					row: {
						colors: ['#f3f4f5', '#fff'],
					},
				},
				chart: {
					events: {
						dataPointSelection: (event, obj2, obj) => {
							const data =
								obj.w.config.series[obj.seriesIndex].data[
									obj.dataPointIndex
								];
							let mes = "<ul class='pa-4 schedule-list'>";
							if (data.SubjectName)
								mes += '<li><b>المادة: </b>' + data.SubjectName;
							if (data.TeacherName)
								mes += '<li><b>المدرس: </b>' + data.TeacherName;
							if (data.RoomName)
								mes += '<li><b>القاعة: </b>' + data.RoomName;
							if (data.SpecialtyName)
								mes += '<li><b>الصف: </b>' + data.SpecialtyName;
							if (data.CourseName)
								mes += '<li><b>الشعبة: </b>' + data.CourseName;
							mes += '</ul>';
							this.dialogText = mes;
							this.showDialog = true;
						},
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true,
					textAnchor: 'middle',
					formatter: function (x, obj, y) {
						const name = obj.w.config.series[obj.seriesIndex].data[
							obj.dataPointIndex
						].SubjectName

						function towLine(text) {
							const show = text.split(" ");
							let index = 0
								let firstWord = show[index]
								while(firstWord.length + show[++index].length < 15) {
								firstWord = [firstWord, show[index]].join(" ")
								}
							return [text.slice(0,firstWord.length),text.slice(firstWord.length, text.length - 1)];
						}	
						return name.length > 15 ? towLine(name) : name;
					},
					style: {
						fontSize: '13px',
						fontWeight: '400',
						fontFamily: 'Fairuz, sans-serif',
					},
				},
				plotOptions: {
					bar: {
						distributed: true,
						horizontal: true,
						barHeight: '90%',
						borderRadius: 3,
						dataLabels: {
							hideOverflowingLabels: false,
							position: 'center',
							orientation: 'horizontal',
						},
					},
				},
				yaxis: {
					labels: {
						align: 'center',
						style: {
							fontFamily: 'Fairuz, sans-serif',
							fontSize: '16px',
						},
					},
					// tickAmount: 7,
				},
				xaxis: {
					categories: weekNames,
					type: 'datetime',
					position: 'top',
					labels: {
						format: 'HH:mm',
						style: {
							fontFamily: 'Fairuz, sans-serif',
							fontSize: '14px',
						},
					},
				},
			};
		},

		series() {
			const data = this.data?.map((item) => {
				return {
					...item,
					x: weekNames[item.Day],
					y: [
						new Date(item.StartDate).getTime(),
						new Date(
							item.StartDate + item.Duration * 1000 * 60,
						).getTime(),
					],
				};
			});

			return [
				{
					data: data || [],
				},
			];
		},
	},
};
</script>

<style>
.schedule-list {
	list-style: none;
}
.schedule-list li {
	margin-bottom: 0.3rem;
}
</style>
