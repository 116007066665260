<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<div
		v-else-if="!allowCourses"
		class="text-center text-h5 py-10">
		لا تملك صلاحية الدخول لهنا
	</div>
	<div v-else>
		<!-- tabs -->
		<div class="d-flex flex-wrap justify-start align-center pt-3">
			<div
				@click="tab = 'students'"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'students' }">
				الطلاب
			</div>
			<div
				@click="
					tab = 'tests';
					fetchTests();
					fetchSubjects();
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'tests' }">
				الاختبارات
			</div>
			<div
				@click="
					tab = 'workingHours';
					fetchSchedule();
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'workingHours' }">
				برنامج الدوام
			</div>

			<div
				v-if="newVersion"
				@click="
					tab = 'homeWork';
					getHomeWork()
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'homeWork' }">
				الوظائف
			</div>

			<div
				v-if="newVersion"
				@click="
					tab = 'attendance';
					getAttendance()
				"
				class="mobile-tab font-weight-bold py-1"
				:class="{ active: tab === 'attendance' }">
				الحضور
			</div>
		</div>
		<v-divider></v-divider>

		<!-- main -->
		<v-tabs-items
			v-model="tab"
			class="transparent"
			touchless>
			<!-- students -->
			<v-tab-item
				value="students"
				class="pb-1">
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="studentsHeader"
						:items="studentsItem"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 170px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- name -->
						<template v-slot:item.name="{ item }">
							{{ item.Student.Account.FirstName }}
							{{ item.Student.Account.LastName }}
						</template>
						<!-- attendance -->
						<template v-slot:item.attendance="{ item }">
							{{ item.StudentAttend.StudentRate }} \
							{{ item.StudentAttend.TotalRate }}
						</template>
						<!-- average -->
						<template v-slot:item.Average="{ item }">
							{{ !item.Average || item.Average === 'NaN%' ? '-' : item.Average }}
						</template>
						<!-- actions -->
						<template v-slot:item.action="{ item }">
							<div class="actions">
								<v-btn
									:to="{
										name: 'student',
										params: {
											id: item.Student.Id,
										},
									}"
									title="الملف الشخصي للطالب"
									exact
									depressed
									class="actions-btn myBlueBg">
									<v-icon class="myBlue--text"> mdi-account </v-icon>
								</v-btn>				
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>
			<!-- tests -->
			<v-tab-item
				value="tests"
				class="pb-1">
				<!-- details input -->
				<v-row
					dense
					class="my-2">
					<v-col
						cols="12"
						sm="6"
						md="4">
						<v-autocomplete
							v-model="testSubjectId"
							:items="subjects"
							item-text="Name"
							item-value="Id"
							:loading="loadingTestsSubjects"
							:disabled="loadingTestsSubjects"
							label="المادة"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-autocomplete>
					</v-col>
					<v-col
						cols="12"
						sm="6"
						md="4">
						<v-text-field
							v-model="testDetails"
							:loading="loadingTestsSubjects"
							:disabled="loadingTestsSubjects"
							label="محتوى الاختبار"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>
					<!-- form button -->
					<v-col
						cols="12"
						md="2">
						<v-btn
							:disabled="loadingTests || loadingTestsSubjects"
							:loading="loadingTests || loadingTestsSubjects"
							color="primary"
							@click="filteredTests"
						>
							بحث
						</v-btn>
					</v-col>
				</v-row>

				<!-- table -->
				<v-card class="rounded-lg elevation-1 overflow-hidden">
					<v-data-table
						:headers="testsHeader"
						:items="testsArr || testsItems"
						:loading="loadingTabs || loadingTests"
						item-key="Id"
						@click:row="showTestsExpander"
						:expanded.sync="testsExpanded"
						:show-expand="$vuetify.breakpoint.mobile"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 220px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- date -->
						<template v-slot:item.Date="{ item }">
							{{ moment(item.Date).format('YYYY/MM/DD') }}
						</template>
						<!-- time -->
						<template v-slot:item.time="{ item }">
							<!-- {{
								Math.floor(moment(item.EndTime).diff(item.StartTime, 'minutes') / 60) +
								':' +
								(moment(item.EndTime).diff(item.StartTime, 'minutes') % 60)
							}} -->
							{{ moment(item.StartTime).format('hh:mm') }}
						</template>
						<!-- subject name -->
						<template v-slot:item.SubjectName="{ item }">
							{{ item.SubjectName }}
							<span v-if="$vuetify.breakpoint.mobile"> ({{ item.TestType }}) </span>
						</template>
						<!-- action -->
						<!-- v-if="item.Done" -->
						<template v-slot:item.action="{ item }">
							<div
								
								class="actions"
							>
								<v-btn
									v-if="item.Done"
									:to="{
										name: 'grades',
										params: { id: item.Id },
										query: { courseId: $route.params.id }
									}"
									title="العلامات"
									depressed
									class="actions-btn myGreenBg">
									<v-icon class="myGreen--text"> mdi-note-check </v-icon>
								</v-btn>

								<v-btn
									:to="{
										name: 'gradesEntry',
										params: { id: item.Id },
										query: { courseId: $route.params.id }
									}"
									title="إدخال أو تعديل العلامات"
									depressed
									class="actions-btn myEditColorBg"
								>
								<v-icon class="edit--text"> mdi-pencil </v-icon>
								</v-btn>
							</div>

							
						</template>
						<!-- expander -->
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<div class="px-2 py-4">
									<strong v-if="item.Details">
										{{ item.Details }}
									</strong>
									<div>
										وقت الاختبار :
										{{
											Math.floor(
												moment(item.EndTime).diff(item.StartTime, 'minutes') / 60,
											) +
											':' +
											(moment(item.EndTime).diff(item.StartTime, 'minutes') % 60)
										}}
									</div>
								</div>
							</td>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>
			<!-- working hours -->
			<v-tab-item
				value="workingHours"
				class="pb-1">
				<div
					class="d-flex align-center justify-center"
					style="height: 50vh"
					v-if="loadingTabs">
					<v-progress-circular indeterminate> </v-progress-circular>
				</div>
				<div
					v-else
					class="schedule-container"
					dir="ltr">
					<div
						class="schedule-container__inner"
						dir="rtl">
						<working-hours :data="scheduleItems"></working-hours>
					</div>
				</div>
			</v-tab-item>
			
			<!-- homeWork -->
			<v-tab-item
				value="homeWork"
				class="pb-1"
			>
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<!-- add or edit homework -->
					<v-dialog v-model="dialogAddHomeWork" width="400">
						<v-card>
							<v-card-title class="primary--text">
								إضافة وظيفة جديدة
							</v-card-title>
							
							<v-form ref="formHomework">
								<v-card-text>
									<v-row>
										<v-col cols="12" class="pb-1">
											<v-autocomplete 
												v-model="homeWork.SubjectId"
												:items="subjectsCourse"
												item-text="Name"
												item-value="Id"
												label="المادة"
												outlined
												dense
												hide-details
												class="rounded-lg"
												:disabled="submitLoadingHomeWork"
												:loading="submitLoadingHomeWork"
												:rules="rules.required"
											/>
										</v-col>

										<v-col cols="12" class="pb-1">
											<v-autocomplete 
												v-model="homeWork.TeacherId"
												:items="teachers"
												item-text="FullName"
												item-value="Id"
												label="المدرس"
												outlined
												dense
												hide-details
												class="rounded-lg"
												:disabled="submitLoadingHomeWork"
												:loading="submitLoadingHomeWork"
												:rules="rules.required"
											/>
										</v-col>

										<v-col cols="12" class="pb-1">
											<v-textarea 
												v-model="homeWork.Details"
												label="تفاصيل الوظيفة"
												outlined
												dense
												hide-details
												class="rounded-lg"
												:disabled="submitLoadingHomeWork"
												:loading="submitLoadingHomeWork"
												:rules="rules.required"
											/>
										</v-col>

										<v-col cols="12" class="pb-1">
											<v-textarea 
												v-model="homeWork.Notes"
												label="ملاحظات"
												outlined
												dense
												hide-details
												class="rounded-lg"
												rows="2"
												:disabled="submitLoadingHomeWork"
												:loading="submitLoadingHomeWork"
											/>
										</v-col>
										<!-- date homeWork -->
										<v-col cols="12" class="pb-1">
											<div>
												<v-menu
													ref="menuOfDateTime"
													v-model="menuOfDateTime"
													:close-on-content-click="false"
													transition="scale-transition"
													offset-y
													min-width="auto"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															v-model="homeWork.DateTime"
															label="تاريخ الوظيفة"
															prepend-icon="mdi-calendar"
															dense
															outlined
															hide-details
															readonly
															v-bind="attrs"
															v-on="on"
															:disabled="submitLoadingHomeWork"
															:loading="submitLoadingHomeWork"
															class="rounded-lg"
															:rules="rules.required"
														></v-text-field>
													</template>
													<v-date-picker
														v-model="homeWork.DateTime"
														:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
														min="1950-01-01"
														@change="saveDateTime"
													></v-date-picker>
												</v-menu>
											</div>
										</v-col>

										<!-- ExpectedDateToReceive homeWork -->
										<v-col cols="12" class="pb-1">
											<div>
												<v-menu
													ref="menuOfExpectedDateToReceive"
													v-model="menuOfExpectedDateToReceive"
													:close-on-content-click="false"
													transition="scale-transition"
													offset-y
													min-width="auto"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															v-model="homeWork.ExpectedDateToReceive"
															label="التاريخ المتوقع لتسليم الوظيفة "
															prepend-icon="mdi-calendar"
															dense
															outlined
															hide-details
															readonly
															v-bind="attrs"
															v-on="on"
															:disabled="submitLoadingHomeWork"
															:loading="submitLoadingHomeWork"
															class="rounded-lg"
														></v-text-field>
													</template>
													<v-date-picker
														v-model="homeWork.ExpectedDateToReceive"
														:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
														min="1950-01-01"
														@change="saveExpectedDateToReceive"
													></v-date-picker>
												</v-menu>
											</div>
										</v-col>
									</v-row>
								</v-card-text>
							</v-form>

							<v-card-actions>
								<v-spacer />
								<v-btn
									@click="createHomework()"
									color="primary"
									:loading="submitLoadingHomeWork"
								>
									حفظ
								</v-btn>
					
								<v-btn
									outlined
									color="primary"
									@click="dialogAddHomeWork = false"
									:disabled="submitLoadingHomeWork"
								>
									إلغاء
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- delete homework -->
					<v-dialog v-model="deleteHomeworkDialog" width="400">
						<v-card>
							<v-card-title class="alert--text">
								حذف وظيفة 
							</v-card-title>
							<v-card-text>
								هل أنت متأكد أنك تريد حذف هذه الوظيفة؟ 
							</v-card-text>

							<v-card-actions>
								<v-spacer />
								<v-btn
									@click="deleteHomework()"
									color="alert white--text"
									:loading="submitLoadingHomeWork"
								>
									نعم
								</v-btn>
								<v-btn
									@click="deleteHomeworkDialog = false"
									color="alert"
									outlined
									:disabled="submitLoadingHomeWork"
								>
									لا
								</v-btn>
							</v-card-actions>
						</v-card>						
					</v-dialog>
					<v-card-text>
						<v-row>
							<v-col cols="3">
								<!-- start date -->
								<div>
									<v-menu
										ref="menuOfStartDate"
										v-model="menuOfStartDate"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="startDate"
												label="من تاريخ"
												prepend-icon="mdi-calendar"
												dense
												outlined
												hide-details
												readonly
												v-bind="attrs"
												v-on="on"
												:disabled="loading || loadingHomeWork"
												class="rounded-lg"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="startDate"
											:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
											min="1950-01-01"
											@change="saveStartDate"
										></v-date-picker>
									</v-menu>
								</div>
							</v-col>
							<v-col cols="3">
								<!-- end date -->
								<div>
									<v-menu
										ref="menuOfEndDate"
										v-model="menuOfEndDate"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="endDate"
												label="إلى تاريخ"
												prepend-icon="mdi-calendar"
												dense
												outlined
												hide-details
												readonly
												v-bind="attrs"
												v-on="on"
												:disabled="loading || loadingHomeWork"
												class="rounded-lg"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="endDate"
											:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
											min="1950-01-01"
											@change="saveEndDate"
										></v-date-picker>
									</v-menu>
								</div>
							</v-col>

							<!-- button -->
							<v-col>
								<v-btn
									color="primary"
									:loading="loading"
									:disabled="loadingHomeWork"
									@click="getHomeWork()"
								>
									بحث
								</v-btn>
							</v-col>
							
							<v-col class="d-flex justify-end">
								<v-btn 
									@click="dialogAddHomeWork = true" 
									color="myGreen white--text" 
									:disabled="loadingHomeWork"
								>
									إضافة وظيفة جديدة
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
					<v-data-table
						:headers="headersHomeWorks"
						:items="!loadingHomeWork ? homeworks : []"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 170px)"
						fixed-header
						mobile-breakpoint="0"
						:loading="loadingHomeWork"
					>
						<template v-slot:item.SubjectId="{ item }">
							{{ getSubjectById(item.SubjectId).Name }}
						</template>

						<!-- <template v-slot:item.TeacherId="{ item }">
							{{ item.TeacherId ? getTeacherById(item.TeacherId).FullName : null }}
						</template> -->

						<template v-slot:item.DateTime="{ item }">
							{{moment(item.DateTime).format('YYYY-MM-DD')}}
						</template>

						<template v-slot:item.ExpectedDate="{ item }">
							{{ item.ExpectedDate !== null ? moment(item.ExpectedDate).format('YYYY-MM-DD') : '-' }}
						</template>

						<template v-slot:item.action="{ item }">
							<v-row class="actions d-flex justify-end">
								<v-col cols="auto" class="px-0 action">
									<v-btn
										title="تعديل الوظيفة"
										exact
										depressed
										class="actions-btn myEditColorBg"
										small
										@click="homeworkId = item.Id; dialogAddHomeWork = true"
									>
										<v-icon class="edit--text"> mdi-pencil </v-icon>
									</v-btn>
								</v-col>

								<v-col cols="auto" class="px-1 action">
									<v-btn
										@click="homeworkId = item.Id; deleteHomeworkDialog = true"
										title="حذف الوظيفة"
										exact
										depressed
										class="actions-btn myDeleteColorBg"
										small
									>
										<v-icon class="alert--text"> mdi-delete </v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>

			<v-tab-item 
				value="attendance" 
				class="pb-1"
			>
				<!-- filter -->
				<v-row class="mt-2">
					<v-col cols="3" align-self="center">
						<bee-date-picker
							v-model="startDateAttendance"
							input-label="Date"
							elevation="0"
							menu-picker
							dense
							hide-details
							:input-props="{
								outlined: true,
								dense: true
							}"
							:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
							min="1940-01-01"
							:disabled="loadingAttendance"
							:loading="loading"
							@change="getAttendance()"
						></bee-date-picker>
					</v-col>


					<v-col cols="auto" align-self="center">
						<v-btn 
							@click="submitAttendance()"
							color="myGreen white--text" 
							:loading="loadingAttendance"
							:disabled="attendance.map(c => c.type).filter(c => c === 0 || c === null).length === attendance.length"
							>
							حفظ
						</v-btn>
					</v-col>
				</v-row>

				<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
					<v-row>
						<!-- <v-spacer /> -->
						<v-col cols="2">
							<v-checkbox 
								v-model="all"
								v-if="notFoundAtten" 
								@click="attendance.map(c => c.type === 1 ? c.type = 0 : c.type = 1)" 
								label="الكل"
								hide-details
							>
							</v-checkbox>
						</v-col>
						<!-- <v-spacer />
						<v-spacer /> -->
					</v-row>
					<v-data-table
						:headers="attendanceHeader"
						:items="attendance"
						dense
						:items-per-page="-1"
						height="calc(100vh - 170px)"
						fixed-header
						mobile-breakpoint="0"
						:loading="loadingAttendance"
					>

						<template v-slot:item.type="{ item }">
							<v-radio-group
								v-model="item.type"
								row
								hide-details
								class="mt-0"
							>
								<v-radio
									label="موجود"
									:value="1"
								></v-radio>

								<v-radio
									label="غائب"
									:value="2"
								></v-radio>

								<v-radio
									label="متأخر"
									:value="3"
								></v-radio>
								<v-radio
									label="انصراف مبكر"
									:value="4"
								></v-radio>
							</v-radio-group>
						</template>

						<template v-slot:item.Justification="{ item }">
							<v-text-field
								v-model="item.Justification"
								:disabled="item.type !== 2 && item.type !== 3 && item.type !== 4"
							 />
						</template>

						<template v-slot:item.ParentJustification="{ item }">
							<v-text-field
								v-model="item.ParentJustification"
								:disabled="item.type !== 2 && item.type !== 3 && item.type !== 4"
							 />
						</template>
						
						<!-- actions -->
						<template v-slot:item.action="{ item }">
							<div class="actions">
								<v-btn
									:to="{
										name: 'student',
										params: {
											id: item.Student.Id,
										},
									}"
									title="الملف الشخصي للطالب"
									exact
									depressed
									class="actions-btn myBlueBg"
								>
									<v-icon class="myBlue--text"> mdi-account </v-icon>
								</v-btn>
							</div>
						</template>
					</v-data-table>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours.vue';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { newVersion } from '@/global';
import rules from '@/helpers/validation rules'
export default {
	name: 'CourseView',
	components: {
		WorkingHours,
	},

	watch: {
		dialogAddHomeWork(val) {
			if(val === true) {
				if(this.homeworkId !== null) {
					const homework = this.getHomeworkById(this.homeworkId)
					this.homeWork.SubjectId = homework.SubjectId
					this.homeWork.TeacherId = homework.TeacherId
					this.homeWork.Notes =homework.Notes
					this.homeWork.Details = homework?.Details
					this.homeWork.DateTime = this.moment(homework?.DateTime).format('YYYY-MM-DD')
					this.homeWork.ExpectedDateToReceive = homework?.ExpectedDate !== null ? this.moment(homework?.ExpectedDate).format('YYYY-MM-DD') : null
				}
			} else {
				console.log('false')
				this.homeWork.SubjectId = null
				this.homeWork.TeacherId = null
				this.homeWork.Notes = null
				this.homeWork.Details = null
				this.homeWork.DateTime = this.moment().format('YYYY-MM-DD')
				this.homeWork.ExpectedDateToReceive = null
				this.homeworkId = null
				this.$refs.formHomework.reset()
			}
		}
	},

	data() {
		return {
			loadingHomeWork: false,
			homeworkId: null,

			menuOfDateTime: null,

			menuOfExpectedDateToReceive: null,

			loading: true,
			loadingTabs: false,
			loadingTestsSubjects: false,
			tab: 'students',
			
			// filter date
			menuOfStartDate: null,
			startDate: this.moment(this.moment()).subtract(6, 'days').format('YYYY-MM-DD'),

			menuOfEndDate: null,
			endDate: this.moment().format('YYYY-MM-DD'),

			allowCourses: true,

			studentsHeader: [
				{
					text: 'اسم الطالب',
					value: 'name',
					align: 'start',
					sortable: false,
				},
				{
					text: 'نسبة الدوام',
					value: 'attendance',
					align: 'center',
					sortable: false,
				},
				{ text: 'المعدل', value: 'Average', align: 'center' },
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
				},
			],
			studentsItem: [],
			testsItems: [],
			scheduleItems: [],

			testsArr: null,
			loadingTests: false,
			subjects: [],
			testSubjectId: null,
			testDetails: null,

			testsExpanded: [],

			headersHomeWorks: [
				{
					text: 'المادة',
					value: 'SubjectId',
					align: 'center',
					sortable: false,
				},
				{
					text: 'الأستاذ',
					value: 'TeacherName',
					align: 'center',
					sortable: false,
				},
				{
					text: 'التفاصيل',
					value: 'Details',
					align: 'center',
					sortable: false
				},
				{
					text: 'تاريخ الوظيفة',
					value: 'DateTime',
					align: 'center',
					sortable: false
				},
				{
					text: 'الوقت المتوقع لتسليمة الوظيفية',
					value: 'ExpectedDate',
					align: 'center',
					sortable: false
				},
				{
					text: 'الملاحظات',
					value: 'Notes',
					align: 'center',
					sortable: false
				},
				{
					text: '',
					value: 'action',
					align: 'center',
					width:"20%",
					sortable: false
				}
			],
			dialogAddHomeWork: false,
			homeWork: {
				CourseId: Number(this.$route.params.id),
				TeacherId: null,
				Notes: null,
				SubjectId: null,
				Details: null,
				DateTime: this.moment().format('YYYY-MM-DD'),
				ExpectedDateToReceive: null
			},
			submitLoadingHomeWork: false,
			deleteHomeworkDialog: false,

			// attendance
			attendanceItem: [],
			attendance: [],
			startDateAttendance: this.moment().format('YYYY-MM-DD'),
			loadingAttendance: false,
			// notFoundAtten: false,
			newVersion,
			rules,
			all: null
		};	
	},
	computed: {
		testsHeader() {
			const testsHeader = [
				{
					text: 'المادة',
					value: 'SubjectName',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'محتوى الاختبار',
					value: 'Details',
					sortable: false,
				},
				{
					text: 'نوع الاختبار',
					value: 'TestType',
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{ text: 'الوقت', value: 'time', sortable: false },
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},
		...mapState({
			centerId: state => state.auth.centerId,
			userName: state => state.auth.user.userName,
			subjectsCourse: state => state.homeWork.subjects,
			teachers: state => state.teacher.teachersCourse.data,
			homeworks: state => state.homeWork.homeworks
		}),

		...mapGetters({
			getSubjectById: 'homeWork/getSubjectById',
			getTeacherById: 'teacher/getTeacherById',
			getHomeworkById: 'homeWork/getHomeworkById'
		}),

		attendanceHeader() {
			return [
				{
					text: 'الاسم',
					value: 'name',
					align: 'center',
					sortable: false
				},
				{
					text: '',
					value: 'type',
					align: 'start',
					sortable: false
				},
				// {
				// 	text: 'الصف',
				// 	value: "SpecialtyId",
				// 	align: 'center',
				// 	sortable: false
				// },
				// {
				// 	text: 'الشعبة',
				// 	value: "CourseId",
				// 	align: 'center',
				// 	sortable: false
				// },
				{
					text: 'التبرير',
					value: 'Justification',
					align: 'center',
					sortable: false
				},
				{
					text: 'المبرر',
					value: 'ParentJustification',
					align: 'center',
					sortable: false
				}
			]
		},
		notFoundAtten() {
			let atten = false
			if(this.attendance.filter(c => c.HasValueBefore === false).length === this.attendance.length) {
				atten = true
			} else {
				atten = false
			}
			return atten
		}
	},
	methods: {
		fetchStudents() {
			// this.loading = true;
			this.allowCourses = true;
			this.$store
				.dispatch('courses/fetchStudents', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.studentsItem = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowCourses = false;
					}
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		fetchTests() {
			this.loadingTabs = true;
			this.allowCourses = true;
			this.$store
				.dispatch('courses/fetchTests', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.testsItems = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowCourses = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSubjects() {
			this.loadingTestsSubjects = true;
			this.allowCourses = true;
			this.$store
				.dispatch('courses/fetchSubjects', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.subjects = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowCourses = false;
					}
				})
				.finally(() => {
					this.loadingTestsSubjects = false;
				});
		},
		fetchSchedule() {
			this.loadingTabs = true;
			this.allowCourses = true;
			this.$store
				.dispatch('courses/fetchSchedule', {
					courseId: this.$route.params.id,
				})
				.then((res) => {
					this.scheduleItems = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowCourses = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},

		filteredTests() {
			this.loadingTests = true;
			setTimeout(() => {
				this.loadingTests = false;

				this.testsArr = this.testsItems
					.filter((e) => {
						return this.testSubjectId ? e.SubjectId === this.testSubjectId : true;
					})
					.filter((e) => {
						return this.testDetails ? e.Details.includes(this.testDetails) : true;
					});
			}, 500);
		},

		// style methods
		showTestsExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.testsExpanded.findIndex((i) => i === item);
				this.testsExpanded.splice(indexExpanded, 1);
			} else {
				this.testsExpanded.push(item);
			}
		},
		moment,

		saveDateTime (date) {
            this.$refs.menuOfDateTime.save(date);
        },

		saveExpectedDateToReceive (date) {
            this.$refs.menuOfExpectedDateToReceive.save(date);
        },

		saveStartDate (date) {
            this.$refs.menuOfStartDate.save(date);
        },

		saveEndDate (date) {
            this.$refs.menuOfEndDate.save(date);
        },

		getHomeWork () {
			this.loadingHomeWork = true
			this.$store.dispatch('homeWork/getAll', { 
				centerId: this.centerId,
					userName: this.userName, 
				courseId: this.$route.params.id, 
				fromDate: this.startDate, 
				toDate: this.endDate 
			}).finally(() => {
				this.loadingHomeWork = false
			})
		},

		createHomework () {
			if(this.$refs.formHomework.validate()) {
				this.submitLoadingHomeWork = true
				const homeworkWithId = { ...this.homeWork, Id: this.homeworkId}
				this.$store.dispatch('homeWork/createOrUpdate', { 
					centerId: this.centerId,
					 userName: this.userName, 
					homeWorkRequest: homeworkWithId
				}).then(() => {
					this.getHomeWork()
					this.dialogAddHomeWork = false
				}).finally(() => {
					this.submitLoadingHomeWork = false
				})
			}
		},

		deleteHomework() {
			this.submitLoadingHomeWork = true
			this.$store.dispatch('homeWork/delete', {
				centerId: this.centerId,
			 	userName: this.userName, 
				courseId: this.$route.params.id, 
				homeWorkId: this.homeworkId
			}).then(() => {
				this.getHomeWork()
				this.deleteHomeworkDialog = false
				this.submitLoadingHomeWork = false
			}).finally(() => {
				this.homeworkId = null
			})
		},

		getTeachers() {
			return this.$store.dispatch('teacher/fetchAllCourse', {  
				courseId: this.$route.params.id 
			})
		},

		getSubject() {
			return this.$store.dispatch('homeWork/getSubject', { 
				centerId: this.centerId, 
				userName: this.userName, 
				courseId: this.$route.params.id 
			})
		},
		// attendance
		getAttendance() {
			this.all = null
			this.loadingAttendance = true
			this.$store.dispatch('managerCourses/fetchAttendances', {
					day: new Date(
						new Date(this.startDateAttendance).setHours(new Date().getTimezoneOffset() / -60, 0, 0, 0),
					).getTime(),
					courseId: this.$route.params.id
				}).then((res) => {
					if(res.StudentAttendance.length !== 0) {
						this.attendance = res.StudentAttendance.map(c => ({ 
							...c, 
							name: c.Account.FullName,
							type: c.HasValueBefore ? 
								c.HasAttend ? 
									c.IsLate ? 
									3 : 
									c.IsEarlyLeave ? 
									4 
									: 1 
									: 2
									: null,
							AccountId: c.Account.Id,
							HasValueBefore: c.HasValueBefore
						}));
						
						// if(this.attendance.filter(c => c.HasValueBefore === false).length === this.attendance.length) {
						// 	this.notFoundAtten = true
						// }
					} else {
						this.fetchStudents()
						this.attendance = this.studentsItem.map(c => ({
							...c,
							name: c.Student.Account.FullName,
							AccountId: c.Student.AccountId
						}))
					}
					this.loadingAttendance = false
				}).catch(() => {
					this.loadingAttendance = false
				})
		},

		submitAttendance() {
			this.loadingAttendance = true
			this.$store.dispatch('managerCourses/createAttendances', {
				courseId: Number(this.$route.params.id),
				isTeachers: false,
				attendanceRequests: this.attendance.map(c => c.type !== null && c.type !== '' && c.type !== 0 ? ({
					ID: c.Id,
					AccountId: c.AccountId,
					CourseId: Number(this.$route.params.id),
					Date: this.startDateAttendance,
					HasAttend: c.type !== 2,
					IsLate: c.type === 3,
					IsEarlyLeave: c.type === 4,
					// EarlyLeaveMinutes:
					// LateMinutes
					Justification: c.Justification,
					ParentJustification: c.ParentJustification
				}) : null).filter(c => c !== null)
			}).finally(() => {
				this.loadingAttendance = false
			})
		}
		
	},

	mounted() {
		this.loading = true
		Promise.all([
			this.fetchStudents(),
			this.getTeachers(),
			this.getSubject()
		]).finally(() => {
			this.loading = false
		})
	},

	metaInfo: {
		title: 'Course',
		titleTemplate: '%s | Edunix',
	},
};
</script>

<style scoped></style>
