<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<v-row>
			<!-- profile -->
			<v-col
				cols="12"
				lg="3">
				<div class="text-center">
					<div class="d-block d-sm-flex d-lg-block justify-space-around align-center">
						<!-- search -->
						<v-btn
							class="mb-sm-2 mb-0"
							text
							@click="dialog = true">
							<v-icon left>mdi-magnify</v-icon>
							بحث عن طالب
						</v-btn>
						<!-- name -->
						<h4 class="text-h5 font-weight-bold mb-2">
							{{ studentProfile.FullName }}
						</h4>

						<!-- specialty and course -->
						<h5 class="text-body-2 text--secondary mb-5">
							الصف:
							{{ studentProfile.SpecialtyName }}
							<br />
							الشعبة :
							{{ studentProfile.CourseName }}
						</h5>
					</div>

					<v-row>
						<!-- attendance -->
						<v-col
							cols="4"
							lg="12">
							<div class="d-flex flex-column align-center">
								<div class="text-caption">الدوام المحقق</div>
								<div>
									<span class="text-h5 font-weight-bold primary--text">
										{{ studentProfile.Attendance.StudentRate }}
									</span>
									\
									{{ studentProfile.Attendance.TotalRate }}
								</div>
								<div
									class="text-caption font-weight-bold"
									v-if="
										studentProfile.Attendance.TotalRate -
										studentProfile.Attendance.StudentRate
									">
									{{
										studentProfile.Attendance.TotalRate -
										studentProfile.Attendance.StudentRate
									}}
									غيابات
								</div>
							</div>
						</v-col>

						<!-- rank -->
						<v-col
							cols="4"
							lg="12">
							<div
								class="d-flex flex-column align-center"
								v-if="studentProfile.StudentRank?.Rank">
								<div class="text-caption">ترتيب الطالب على الشعبة</div>
								<div class="text-h5 font-weight-bold primary--text">
									{{ studentProfile.StudentRank?.Rank }}
								</div>
							</div>
						</v-col>

						<!-- average -->
						<v-col
							cols="4"
							lg="12">
							<div class="d-flex flex-column align-center">
								<div class="text-caption">المعدل</div>
								<div
									v-if="!studentProfile.Average || studentProfile.Average === '-'"
									class="text-h5 font-weight-bold primary--text">
									-
								</div>
								<template v-else-if="studentProfile.Average.split('/').length > 1">
									<div class="text-h5 font-weight-bold primary--text">
										{{ studentProfile.Average.split('/')[0] }}
									</div>
									<div class="text-caption font-weight-bold">
										\
										{{ studentProfile.Average.split('/')[1] }}
									</div>
								</template>
								<template v-else>
									<div class="text-h5 font-weight-bold primary--text">
										{{ studentProfile.Average }}
									</div>
								</template>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<v-divider
				vertical
				v-if="!$vuetify.breakpoint.mdAndDown"></v-divider>

			<!-- main -->
			<v-col
				cols="12"
				lg="9">
				<!-- tabs -->
				<div class="d-flex flex-wrap justify-start align-center">
					<!-- profile -->
					<div
						@click="tab = 'profile'"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'profile' }">
						معلومات الطالب
					</div>
					<!-- tests -->
					<div
						@click="
							tab = 'tests';
							fetchTests();
							fetchSubjects();
							fetchSubjectsAll()
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'tests' }">
						الاختبارات
					</div>
					<!-- notes -->
					<div
						@click="
							tab = 'behavior';
							fetchNotes();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'behavior' }">
						ملاحظات سلوكية
					</div>
					<!-- manager notes -->
					<div
						@click="
							tab = 'notes';
							fetchManagerNotes();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'notes' }">
						ملاحظات إدارية
					</div>
					<!-- attendance -->
					<div
						@click="
							tab = 'attendance';
							fetchAttendance();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'attendance' }">
						سجل الحضور
					</div>
					<!-- funds -->
					<div
						@click="
							tab = 'finance';
							fetchFunds();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'finance' }">
						سجل مالي
					</div>
					<!-- working hour -->
					<div
						@click="
							tab = 'workingHours';
							fetchSchedule();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'workingHours' }">
						برنامج الدوام
					</div>
					<!-- chat -->
					<div
						@click="
							tab = 'massages';
							fetchMessages();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'massages' }">
						الرسائل
					</div>
					<!-- subjects -->
					<div
						@click="
							tab = 'subjects';
							fetchStaticsAndSubjects();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'subjects' }">
						المواد
					</div>
					<!-- report -->
					<div
						@click="
							tab = 'report';
							fetchMonthsForReports();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'report' }">
						التقرير الشهري
					</div>
				</div>
				<v-chip-group
					v-if="false"
					class="mobile-chips"
					active-class="chipsColorActive"
					v-model="tab"
					column
					mandatory>
					<!-- profile -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="profile"
							@click="tab = 'profile'"
							:class="{ active: tab === 'profile' }">
							معلومات الطالب
						</v-chip>
					</div>
					<!-- tests -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="tests"
							@click="fetchTests()"
							:class="{ active: tab === 'tests' }">
							الاختبارات
						</v-chip>
					</div>
					<!-- notes -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="behavior"
							@click="fetchNotes();"
							:class="{ active: tab === 'behavior' }">
							ملاحظات سلوكية
						</v-chip>
					</div>
					<!-- manager notes -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="notes"
							@click="fetchManagerNotes()"
							:class="{ active: tab === 'notes' }">
							ملاحظات إدارية
						</v-chip>
					</div>
					<!-- attendance -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="attendance"
							@click="fetchAttendance()"
							:class="{ active: tab === 'attendance' }">
							سجل الحضور
						</v-chip>
					</div>
					<!-- funds -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="finance"
							@click="fetchFunds()"
							:class="{ active: tab === 'finance' }">
							سجل مالي
						</v-chip>
					</div>
					<!-- working hour -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="workingHours"
							@click="fetchSchedule()"
							:class="{ active: tab === 'workingHours' }">
							برنامج الدوام
						</v-chip>
					</div>
					<!-- chat -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="massages"
							@click="fetchMessages()"
							:class="{ active: tab === 'massages' }">
							الرسائل
						</v-chip>
					</div>
					<!-- subjects -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="subjects"
							@click="fetchStaticsAndSubjects()"
							:class="{ active: tab === 'subjects' }">
							المواد
						</v-chip>
					</div>
					<!-- report -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="subjects"
							@click="fetchMonthlyReport()"
							:class="{ active: tab === 'report' }">
							المواد
						</v-chip>
					</div>
				</v-chip-group>
				<v-divider></v-divider>
				<v-tabs-items
					v-model="tab"
					class="transparent"
					touchless>
					<!-- student profile -->
					<v-tab-item
						value="profile"
						class="pb-1">
						<div
							v-if="!allowProfile"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<v-card
							v-else
							class="rounded-lg elevation-1 overflow-hidden mt-6 pa-4">
							<div :style="`column-count: ${$vuetify.breakpoint.mobile ? 1 : 2}`">
								<div>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											العنوان
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.Address | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											تاريخ الميلاد
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													moment(studentProfile.BirthDate).format('D/M/YYYY')
														| isAvailable(!!studentProfile.BirthDate)
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الهاتف
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.Phone | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم ولي الامر المعتمد
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.ParentPhone | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الباص
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.BusNumber | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											اسم الاب
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.FatherName | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											مهنة الاب
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.FatherWork | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الاب
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.FatherPhone | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											اسم الام
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.MotherName | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											مهنة الام
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.MotherWork | isAvailable }}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الام
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{ studentProfile.MotherPhone | isAvailable }}
											</template>
										</v-col>
									</v-row>
								</div>
							</div>
						</v-card>
					</v-tab-item>

					<!-- student test -->
					<v-tab-item
						value="tests"
						class="pb-1">
						<div
							v-if="!allowTests"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<template v-else>
							<!-- filter form -->
							<v-form class="mt-2">
								<v-row
									dense
									class="ma-0"
									align="center">
									<!-- test subject -->
									<v-col
										cols="12"
										md="5">
										<v-autocomplete
											v-model="testSubjectId"
											:items="subjectsItems"
											item-text="Name"
											item-value="Id"
											:loading="loadingTestsSubjects"
											:disabled="loadingTestsSubjects"
											label="المادة"
											outlined
											dense
											hide-details
											clearable
											:menu-props="{ offsetY: true }"
											class="rounded-lg"></v-autocomplete>
									</v-col>
									<!-- test detail -->
									<v-col
										cols="12"
										md="5">
										<v-text-field
											v-model="testDetails"
											:loading="loadingTestsSubjects"
											:disabled="loadingTestsSubjects"
											label="محتوى الاختبار"
											outlined
											dense
											hide-details
											append-icon="mdi-magnify"
											class="rounded-lg"></v-text-field>
									</v-col>
									<!-- form button -->
									<v-col
										cols="12"
										md="2">
										<v-btn
											:disabled="loadingTests || loadingTestsSubjects"
											:loading="loadingTests || loadingTestsSubjects"
											color="primary"
											@click="filteredTests">
											بحث
										</v-btn>
									</v-col>
								</v-row>
							</v-form>

							<!-- table -->
							<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
								<v-data-table
									sort-by="Test.Date"
									:headers="testsHeader"
									:items="testsArr || testsItems"
									:loading="loadingTabs || loadingTests"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 220px)"
									fixed-header
									mobile-breakpoint="0">
									<!-- mark -->
									<template v-slot:item.mark="{ item }">
										<div
											class="font-weight-bold"
											style="font-size: inherit">
											<template v-if="item.IsAbsent"> لم يقدم </template>
											<template v-else>
												<span
													:class="
														item.Mark >= item.Test.MinGrade
															? 'myGreen--text'
															: 'red--text'
													">
													{{ item.Mark }}
												</span>
												\
												{{ item.TestMaxMark }}
											</template>
										</div>
									</template>
									<!-- date -->
									<template v-slot:item.Test.Date="{ item }">
										{{ moment(item.Test.Date).format('YYYY/MM/DD') }}
									</template>
								</v-data-table>
							</v-card>
						</template>
					</v-tab-item>

					<!-- student behavior -->
					<v-tab-item
						value="behavior"
						class="pb-1"
					>
						<div
							v-if="!allowBehavior"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>

						<div v-else class="mt-2">
							<!-- dialog add note behavior -->
							<v-dialog v-model="addNoteBehaviorDialog" width="450">
								<v-card>
									<v-card-title class="primary--text pb-0">
										إضافة ملاحظة سلوكية لطالب
									</v-card-title>
									<v-form ref="fromBehavior">
										<v-card-text>
											<v-row class="pt-0">
												<v-col cols="12" class="pb-1">
													<v-radio-group
														v-model.number="radioGroup"
														row
														class="mt-0"
														hide-details
													>
														<v-radio
															label="مدرس"
															:value="0"
														></v-radio>

														<v-radio
															label="موجه"
															:value="2"
														></v-radio>

														<v-radio
															label="مدير"
															:value="1"
														></v-radio>

													</v-radio-group>
													
												</v-col>
												<!-- note from -->
												<v-col cols="6" class="pb-1">
													<v-autocomplete 
														v-model="notes.TeacherId"
														:items="teachers"
														item-text="FullName"
														item-value="Id"
														label="المدرس"
														outlined
														dense
														hide-details
														class="rounded-lg"
														:disabled="submitLoading || radioGroup !== 0"
														:loading="submitLoading"
														:rules="radioGroup === 0 ? rules.required : []"
													/>
												</v-col>
												
												<!-- subject name -->
												<v-col cols="6" class="pb-1">
													<v-autocomplete 
														v-model="notes.SubjectId"
														:items="subjectsForCourseId"
														item-text="Name"
														item-value="Id"
														label="المادة"
														outlined
														dense
														hide-details
														class="rounded-lg"
														:disabled="submitLoading || radioGroup !== 0"
														:loading="submitLoading"
														:rules="radioGroup === 0 ? rules.required : []"
													/>
												</v-col>

												<v-col cols="12" class="pb-1">
													<v-autocomplete 
														v-model="notes.SupervisorId"
														:items="staff.filter(c => c.RoleId === radioGroup)"
														item-text="FullName"
														item-value="ID"
														label="الموجه أو المدير"
														outlined
														dense
														hide-details
														class="rounded-lg"
														:disabled="submitLoading || radioGroup === 0"
														:loading="submitLoading"
														:rules="radioGroup !== 0 ? rules.required : []"
													/>
												</v-col>

												<!-- date -->
												<v-col cols="12" class="pb-1">
													<div>
														<v-menu
															ref="menuOfDate"
															v-model="menuOfDate"
															:close-on-content-click="false"
															transition="scale-transition"
															offset-y
															min-width="auto"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	v-model="notes.Date"
																	label="التاريخ"
																	prepend-icon="mdi-calendar"
																	dense
																	outlined
																	hide-details
																	readonly
																	v-bind="attrs"
																	v-on="on"
																	class="rounded-lg"
																	:disabled="submitLoading"
																	:loading="submitLoading"
																	:rules="rules.required"
																></v-text-field>
															</template>
															<v-date-picker
																v-model="notes.Date"
																:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
																min="1950-01-01"
																@change="saveDate"
															></v-date-picker>
														</v-menu>
													</div>
												</v-col>

												<!-- note -->
												<v-col cols="12" class="pb-1">
													<v-textarea 
														v-model="notes.Content"
														label="ملاحظات"
														outlined
														dense
														hide-details
														class="rounded-lg"
														rows="2"
														:disabled="submitLoading"
														:loading="submitLoading"
														:rules="rules.required"
													/>
												</v-col>
											</v-row>
										</v-card-text>

										<v-card-actions>
											<v-spacer />
											<v-btn
												@click="createOrUpdateNoteBe()"
												color="primary"
												:loading="submitLoading"
											>
												حفظ
											</v-btn>
					
											<v-btn
												outlined
												color="primary"
												@click="addNoteBehaviorDialog = false"
												:disabled="submitLoading"
											>
												إلغاء
											</v-btn>
										</v-card-actions>
									</v-form>
								</v-card>
							</v-dialog>

							<!-- delete dialog -->
							<v-dialog v-model="deleteNoteBehaviorDialog" width="400">
								<v-card>
									<v-card-title class="alert--text">
										حذف الملاحظة السلوكية 
									</v-card-title>
									<v-card-text>
										هل أنت متأكد أنك تريد حذف هذه الملاحظة؟ 
									</v-card-text>

									<v-card-actions>
										<v-spacer />
										<v-btn
											@click="deleteBehaviorNote()"
											color="alert white--text"
											:loading="submitLoading"
										>
											نعم
										</v-btn>
										<v-btn
											@click="deleteNoteBehaviorDialog = false"
											color="alert"
											outlined
											:disabled="submitLoading"
										>
											لا
										</v-btn>
									</v-card-actions>
								</v-card>						
							</v-dialog>

							<v-btn
								v-if="newVersion"
								@click="addNoteBehaviorDialog = true"
								color="myGreen white--text" 
								:disabled="loadingTabs"
							> 
								إضافة ملاحظة سلوكية جديدة 
							</v-btn>
							<v-card
								class="rounded-lg elevation-1 overflow-hidden mt-2"
							>
								<v-data-table
									:headers="behaviorHeader"
									:items="behaviorItems"
									:loading="loadingTabs"
									item-key="Id"
									@click:row="showBehaviorExpander"
									:expanded.sync="behaviorExpanded"
									:show-expand="$vuetify.breakpoint.mobile"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 180px)"
									fixed-header
									mobile-breakpoint="0">
									<!-- mark template -->
									<template v-slot:item.teacher="{ item }">
										{{ item.TeacherName || item.SupervisorName }}
									</template>
									<!-- mark template -->
									<template v-slot:item.Date="{ item }">
										{{ moment(item.Date).format('YYYY/MM/DD') }}
									</template>
									<!-- expand template -->
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length">
											<div class="px-2 py-4">
												{{ item.Content }}
												<br />
												<span class="text--secondary">
													{{ moment(item.Date).format('YYYY/MM/DD') }}
												</span>
											</div>
										</td>
									</template>

									<template v-slot:item.action="{ item }">
										<div class="actions d-flex justify-end">
											<v-btn
												title="تعديل الملاحظة"
												exact
												depressed
												class="actions-btn myEditColorBg"
												small
												@click="noteBehaviorId = item.Id; addNoteBehaviorDialog = true"
											>
												<v-icon class="edit--text"> mdi-pencil </v-icon>
											</v-btn>

											<v-btn
												title="حذف الملاحظة"
												exact
												depressed
												class="actions-btn myDeleteColorBg"
												small
												@click="noteBehaviorId = item.Id; deleteNoteBehaviorDialog = true"
											>
												<v-icon class="alert--text"> mdi-delete </v-icon>
											</v-btn>
										</div>
									</template>
									
								</v-data-table>
							</v-card>
						</div>
					</v-tab-item>

					<!-- student notes -->
					<v-tab-item
						value="notes"
						class="pb-1">
						<div
							v-if="!allowNotes"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>

						<div v-else class="mt-2">
							<!-- dialog add note behavior -->
							<v-dialog v-model="addNoteDialog" width="450">
								<v-card>
									<v-card-title class="primary--text pb-0">
										إضافة ملاحظة إدارية لطالب
									</v-card-title>
									<v-form ref="formManager">
										<v-card-text>
											<v-row class="pt-0">
												<!-- note from -->
												<v-col cols="6" class="pb-1">
 													<v-autocomplete 
														v-model="noteManager.NoteTypeId"
														:items="notesTypes"
														item-value="ID"
														item-text="Name"
														label="نوع الملاحظة"
														outlined
														dense
														hide-details
														class="rounded-lg"
														:disabled="submitLoading"
														:loading="submitLoading"
														:rules="rules.required"
													/>
												</v-col>

												<!-- date -->
												<v-col cols="6" class="pb-1">
													<div>
														<v-menu
															ref="menuOfDate"
															v-model="menuOfDateNoteManager"
															:close-on-content-click="false"
															transition="scale-transition"
															offset-y
															min-width="auto"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	v-model="noteManager.Date"
																	label="التاريخ"
																	prepend-icon="mdi-calendar"
																	dense
																	outlined
																	hide-details
																	readonly
																	v-bind="attrs"
																	v-on="on"
																	class="rounded-lg"
																	:disabled="submitLoading"
																	:loading="submitLoading"
																	:rules="rules.required"
																></v-text-field>
															</template>
															<v-date-picker
																v-model="noteManager.Date"
																:max-date="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
																min="1950-01-01"
																@change="saveDate"
															></v-date-picker>
														</v-menu>
													</div>
												</v-col>

												<!-- note -->
												<v-col cols="12" class="pb-1">
													<v-textarea 
														v-model="noteManager.Note"
														label="ملاحظات"
														outlined
														dense
														hide-details
														class="rounded-lg"
														rows="2"
														:disabled="submitLoading"
														:loading="submitLoading"
														:rules="rules.required"
													/>
												</v-col>
											</v-row>
										</v-card-text>

										<v-card-actions>
											<v-spacer />
											<v-btn
												@click="createOrUpdateNoteManager()"
												color="primary"
												:loading="submitLoading"
											>
												حفظ
											</v-btn>
					
											<v-btn
												outlined
												color="primary"
												@click="addNoteDialog = false"
												:disabled="submitLoading"
											>
												إلغاء
											</v-btn>
										</v-card-actions>
									</v-form>
								</v-card>
							</v-dialog>
							<!-- -->
							<v-btn
								v-if="newVersion"
								@click="addNoteDialog = true"
								color="myGreen white--text" 
								:disabled="loadingTabs"
							> 
								إضافة ملاحظة إدارية جديدة 
							</v-btn>

							<!-- delete dialog -->
							<v-dialog v-model="deleteNoteManagerDialog" width="400">
								<v-card>
									<v-card-title class="alert--text">
										حذف الملاحظة الإدارية 
									</v-card-title>
									<v-card-text>
										هل أنت متأكد أنك تريد حذف هذه الملاحظة؟ 
									</v-card-text>

									<v-card-actions>
										<v-spacer />
										<v-btn
											@click="deleteManagerNote()"
											color="alert white--text"
											:loading="submitLoading"
										>
											نعم
										</v-btn>
										<v-btn
											@click="deleteNoteManagerDialog = false"
											color="alert"
											outlined
											:disabled="submitLoading"
										>
											لا
										</v-btn>
									</v-card-actions>
								</v-card>						
							</v-dialog>

							<v-card
								class="rounded-lg elevation-1 overflow-hidden mt-2">
								<v-data-table
									:headers="notesHeader"
									:items="notesItems"
									:loading="loadingTabs"
									item-key="Id"
									@click:row="showNotesExpander"
									:expanded.sync="notesExpanded"
									:show-expand="$vuetify.breakpoint.mobile"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 180px)"
									fixed-header
									mobile-breakpoint="0">
									<!-- mark template -->
									<template v-slot:item.Date="{ item }">
										{{ moment(item.Date).format('YYYY/MM/DD') }}
									</template>
									<!-- expand template -->
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length">
											<div class="px-2 py-4">
												{{ item.Note }}
											</div>
										</td>
									</template>
									<!-- actions -->
									<template v-slot:item.action="{ item }">
										<div class="actions d-flex justify-end">
											<v-btn
												title="تعديل الملاحظة"
												exact
												depressed
												class="actions-btn myEditColorBg"
												@click="noteManagerId = item.Id; addNoteDialog = true"
											>
												<v-icon class="edit--text"> mdi-pencil </v-icon>
											</v-btn>

											<v-btn
												title="حذف الملاحظة"
												exact
												depressed
												class="actions-btn myDeleteColorBg"
												small
												@click="noteManagerId = item.Id; deleteNoteManagerDialog = true"
											>
												<v-icon class="alert--text"> mdi-delete </v-icon>
											</v-btn>
										</div>
									</template>
								</v-data-table>
							</v-card>
						</div>
					</v-tab-item>

					<!-- student attendance -->
					<v-tab-item
						value="attendance"
						class="pb-1">
						<div
							v-if="!allowAttendance"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<v-card
							v-else
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="attendanceHeader"
								:items="attendanceItems"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showAttendanceExpander"
								:expanded.sync="attendanceExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 180px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- date -->
								<template v-slot:item.type="{ item }">
									{{ item.HasAttend ? (item.IsLate ? 'متأخر' : 'انصراف مبكر') : 'غياب' }}
								</template>

								<!-- date -->
								<template v-slot:item.date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>

								<!-- date -->
								<template v-slot:item.Justification="{ item }">
									{{ item.Justification | isAvailable }}
								</template>
								<!-- date -->

								<template v-slot:item.ParentJustification="{ item }">
									{{ item.ParentJustification | isAvailable }}
								</template>

								<!-- expanded template -->
								<template v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											<span>{{ item.Justification }}</span>
											<span v-if="$vuetify.breakpoint.mobile">
												({{ item.ParentJustification }})
											</span>
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- student finance -->
					<v-tab-item
						value="finance"
						class="pb-1">
						<div
							v-if="!allowFinance"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<template v-else>
							<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
								<v-data-table
									:headers="financeHeader"
									:items="financeItems.AllFunds?.filter((e) => e.InAmount || e.OutAmount)"
									:loading="loadingTabs"
									item-key="Id"
									@click:row="showFinanceExpander"
									:expanded.sync="financeExpanded"
									:show-expand="$vuetify.breakpoint.mobile"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 320px)"
									fixed-header
									mobile-breakpoint="0">
									<!-- funds template -->
									<template v-slot:item.Date="{ item }">
										{{ moment(item.Date).format('YYYY/MM/DD') }}
									</template>
									<!-- funds template -->
									<template v-slot:item.type="{ item }">
										{{ item.FundType?.Name }}
									</template>
									<!-- receipt template -->
									<template v-slot:item.InAmount="{ item }">
										<span class="font-weight-bold">
											{{
												new Intl.NumberFormat().format(item.InAmount)
													| isAvailable(item.InAmount)
											}}
										</span>
									</template>
									<!-- payment template -->
									<template v-slot:item.OutAmount="{ item }">
										<span class="font-weight-bold">
											{{
												new Intl.NumberFormat().format(item.OutAmount)
													| isAvailable(item.OutAmount)
											}}
										</span>
									</template>
									<!-- expanded template -->
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length">
											<div class="px-2 py-4">
												<strong v-if="item.Details">
													{{ item.FundType?.Name }}/{{ item.Details }}
													<br />
												</strong>
												<span v-if="item.BookId">
													رقم الدفتر: {{ item.BookId }}
												</span>
												<span v-if="item.ReceiptId">
													| رقم الوصل:
													{{ item.ReceiptId }}
												</span>
											</div>
										</td>
									</template>
								</v-data-table>
							</v-card>
							<v-card class="rounded-lg elevation-1 overflow-hidden mt-4 pa-6">
								<div
									class="d-flex justify-center"
									v-if="loadingTabs">
									<v-progress-circular indeterminate> </v-progress-circular>
								</div>
								<v-row v-else>
									<v-col
										cols="12"
										sm="7">
										مستحقات التعليم:
										<strong>
											{{
												Intl.NumberFormat().format(
													financeItems.TotalInAmountEducation,
												)
											}}
											\
											{{ Intl.NumberFormat().format(financeItems.TotalEducation) }}
										</strong>
									</v-col>
									<v-col
										cols="12"
										sm="5">
										الباقي:
										<strong>
											{{
												Intl.NumberFormat().format(
													financeItems.TotalEducation -
														financeItems.TotalInAmountEducation,
												)
											}}
										</strong>
									</v-col>
									<v-col
										cols="12"
										sm="6">
										مستحقات مواصلات:
										<strong>
											{{
												Intl.NumberFormat().format(
													financeItems.TotalInAmountTransports,
												)
											}}
											\
											{{ Intl.NumberFormat().format(financeItems.TotalTransport) }}
										</strong>
									</v-col>
								</v-row>
							</v-card>
						</template>
					</v-tab-item>

					<!-- student schedule -->
					<v-tab-item
						value="workingHours"
						class="pb-1">
						<div
							v-if="!allowSchedule"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<template v-else>
							<div
								class="d-flex align-center justify-center"
								style="height: 50vh"
								v-if="loadingTabs">
								<v-progress-circular indeterminate> </v-progress-circular>
							</div>
							<div
								class="schedule-container"
								dir="ltr"
								v-else>
								<div
									class="schedule-container__inner"
									dir="rtl">
									<working-hours :data="schedule"></working-hours>
								</div>
							</div>
						</template>
					</v-tab-item>

					<!-- student massages -->
					<v-tab-item
						value="massages"
						class="pb-1">
						<div
							v-if="loadingTabs"
							class="d-flex justify-center align-center"
							style="height: 50vh">
							<v-progress-circular indeterminate> </v-progress-circular>
						</div>
						<div
							v-else-if="!allowChat"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<chat
							v-else
							@update="fetchMessages"
							:messages="messages"
							:student="{
								StudentId: $route.params.id,
								StudentUserName: studentProfile.UserName || studentProfile.FullName,
								CourseName: studentProfile.CourseName,
							}"></chat>
					</v-tab-item>

					<!-- student subjects -->
					<v-tab-item
						value="subjects"
						class="pb-1">
						<div
							v-if="!allowSubjects"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<template v-else>
							<div class="text-body-1 font-weight-bold mt-2">
								معدلات:
								{{ selectedSubject || 'كل المواد' }}
							</div>

							<div
								class="d-flex align-center justify-center"
								style="height: 80vh"
								v-if="loadingTabs">
								<v-progress-circular indeterminate> </v-progress-circular>
							</div>

							<template v-else>
								<div
									class="d-flex align-center justify-center"
									style="height: 200px"
									v-if="loadingSubject">
									<v-progress-circular indeterminate> </v-progress-circular>
								</div>
								<!-- chart -->
								<average-chart
									v-else
									:data="averages"></average-chart>
								<!-- table -->
								<v-card class="rounded-lg elevation-1 overflow-hidden mt-n4">
									<v-data-table
										:headers="subjectsHeader"
										:items="[
											{
												all: true,
												SubjectName: 'الكل',
												SubjectAverage: statics.Average,
												MaxMarkCount: subjects.reduce(
													(a, c) => Number(a) + Number(c.MaxMarkCount),
													0,
												),
											},
											...subjects,
										]"
										item-key="SubjectId"
										@click:row="fetchSubjectStatics"
										dense
										:items-per-page="-1"
										hide-default-footer
										height="calc(100vh - 400px)"
										fixed-header
										mobile-breakpoint="0">
										<template v-slot:item.SubjectAverage="{ item }">
											{{
												item.SubjectAverage.includes('/')
													? item.SubjectAverage
													: item.SubjectAverage.includes('%')
													? item.SubjectAverage
													: (item.SubjectAverage % 1
															? Number(item.SubjectAverage).toFixed(2)
															: item.SubjectAverage) + '%'
											}}
										</template>
									</v-data-table>
								</v-card>
							</template>
						</template>
					</v-tab-item>

					<!-- report -->
					<v-tab-item
						value="report"
						class="pb-1">
						<div
							v-if="!allowReport"
							class="text-center text-h5 py-10">
							لا تملك صلاحية الدخول لهنا
						</div>
						<div v-else>
							<v-row
								class="my-0"
								dense>
								<v-col
									cols="12"
									md="6"
									lg="3">
									<v-select
										v-model="selectedReportMonth"
										@change="fetchReport"
										:disabled="loadingTabs"
										:loading="loadingTabs"
										:items="
											reportMonths.map((e) => ({
												value: e,
												text: moment(e).locale('ar').format('YYYY / MMMM'),
											}))
										"
										label="لشهر"
										outlined
										dense
										hide-details
										clearable
										:menu-props="{ offsetY: true }"
										class="rounded-lg"></v-select>
								</v-col>
							</v-row>
							<!-- table -->
							<v-card class="rounded-lg elevation-1 overflow-hidden mt-1">
								<v-data-table
									sort-by="Test.Date"
									:headers="reportHeader"
									:items="reportItems || []"
									:loading="loadingReport"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 300px)"
									fixed-header
									mobile-breakpoint="0">
									<!-- mark -->
									<template v-slot:item.mark="{ item }">
										<div
											class="font-weight-bold"
											style="font-size: inherit">
											<template v-if="item.IsAbsent"> لم يقدم </template>
											<template v-else>
												<span
													:class="
														item.Mark >= item.Test.MinGrade
															? 'myGreen--text'
															: 'red--text'
													">
													{{ item.Mark }}
												</span>
												\
												{{ item.TestMaxMark }}
											</template>
										</div>
									</template>

									<!-- date -->
									<template v-slot:item.Test.Date="{ item }">
										{{ moment(item.Test.Date).format('YYYY/MM/DD') }}
									</template>
								</v-data-table>
							</v-card>

							<v-card
								class="rounded-lg elevation-1 overflow-hidden mt-1 pa-4 px-8"
								v-if="reportStatics">
								<div class="d-flex justify-space-between align-center">
									<div class="text-center font-weight-bold primary--text text-h5">
										{{ reportStatics.MonthlyAverage }}
										<br />({{ reportStatics.Rate }})
									</div>
									<div>
										<span class="font-weight-bold primary--text text-h5">
											{{ reportStatics.Rank }}
										</span>
										على الشعبة
									</div>
									<div>
										<span class="font-weight-bold primary--text text-h5">
											{{ reportStatics.CountOfTotalMark }}
										</span>
										علامات تامة
									</div>
								</div>
							</v-card>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<!-- search dialog -->
		<v-dialog
			v-model="dialog"
			width="800">
			<v-card class="pa-5">
				<!-- filter form  -->
				<v-row
					class="ma-0"
					align="center"
					dense>
					<v-col
						cols="6"
						sm="4"
						lg="3">
						<v-select
							v-model="selectedSpecialtyId"
							@click:clear="
								selectedSpecialtyId = null;
								selectedCourseId = null;
							"
							:items="specialties"
							item-text="SpecialtyName"
							item-value="SpecialtyId"
							label="الصف"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="6"
						sm="4"
						lg="3">
						<v-select
							v-model="selectedCourseId"
							@click:clear="selectedSpecialtyId = null"
							:disabled="!selectedSpecialtyId"
							:items="specialties.find((e) => e.SpecialtyId === selectedSpecialtyId)?.Courses"
							item-text="CourseName"
							item-value="Id"
							label="الشعبة"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="12"
						sm="4"
						lg="3">
						<v-text-field
							v-model="searchName"
							:disabled="selectedSpecialtyId && !selectedCourseId"
							label="اسم الطالب"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>
					<v-col cols="auto">
						<v-btn
							:disabled="loadingStudents || (!selectedCourseId && !searchName)"
							:loading="loadingStudents"
							color="primary"
							@click="search">
							بحث
						</v-btn>
					</v-col>
				</v-row>
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="studentsHeader"
						:items="students"
						:loading="loadingStudents"
						@click:row="changeStudent"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 200px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- name template -->
						<template v-slot:item.name="{ item }">
							<span>
								{{ item.Student?.Account.FirstName || item.Account.FirstName }}
								{{ item.Student?.Account.LastName || item.Account.LastName }}
							</span>
						</template>

						<!-- specialty template -->
						<template v-slot:item.specialty="{ item }">
							<span>{{ item.Specialty.SpecialtyName }}</span>
							<span v-if="$vuetify.breakpoint.mobile"> - {{ item.Courses.CourseName }}</span>
						</template>
					</v-data-table>
				</v-card>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours.vue';
import Chat from '@/components/Chat.vue';
import AverageChart from '@/components/AverageChart.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { newVersion } from '@/global';
import rules from '@/helpers/validation rules'

export default {
	name: 'StudentView',

	components: {
		WorkingHours,
		Chat,
		AverageChart,
	},

	watch: {
		addNoteBehaviorDialog (val) {
			if(val) {
				if(this.noteBehaviorId !== null) {
					const note = this.behaviorItems.find(c => c.Id === this.noteBehaviorId)
					this.notes.TeacherId = note.TeacherId
					this.notes.SubjectId = note.SubjectId
					this.notes.Date = this.moment(note.Date).format('YYYY-MM-DD')
					this.notes.Content = note.Content
					this.notes.SupervisorId = note.SupervisorId
					this.radioGroup = note.SupervisorId !== null ? this.staff.find(c => c.ID === note.SupervisorId).RoleId === 2 ? 1 : 2 :  0
				}
			} else {
				this.notes.TeacherId = null
				this.notes.SubjectId = null
				this.notes.Date = this.moment().format('YYYY-MM-DD'),
				this.notes.Content = null
				this.noteBehaviorId = null
				this.radioGroup = 0
				this.notes.SupervisorId = null
				this.$refs.fromBehavior.resetValidation()
			}
		},
		addNoteDialog (val) {
			if(val) {
				if(this.noteManagerId !== null) {
					const note = this.notesItems.find(c => c.Id === this.noteManagerId)
					this.noteManager.NoteTypeId = this.notesTypes.find(c => c.Name === note.NoteType)?.ID,
					this.noteManager.Date = this.moment(note.Date).format('YYYY-MM-DD')
					this.noteManager.Note = note.Note
				}
			} else {
				this.noteManager.NoteTypeId = null
				this.noteManager.Date = this.moment().format('YYYY-MM-DD'),
				this.noteManager.Note = null
				this.noteManagerId = null
				this.$refs.formManager.resetValidation()
			}
		},
		AccountIdStudent(val) {
			if(val !== null) {
				this.noteManager.AccountId = val
			}
		}
	},

	data() {
		return {
			deleteNoteBehaviorDialog: false,
			tab: this.$route.query.tab || 'profile',
			dialog: false,

			allowProfile: true,
			allowTests: true,
			allowBehavior: true,
			allowNotes: true,
			allowAttendance: true,
			allowFinance: true,
			allowSchedule: true,
			allowChat: true,
			allowSubjects: true,
			allowReport: true,

			testsArr: null,
			loadingTests: false,
			testSubjectId: null,
			testDetails: null,

			loading: true,
			loadingTabs: true,
			loadingTestsSubjects: false,
			loadingReport: false,

			studentProfile: {},

			behaviorExpanded: [],
			notesExpanded: [],
			financeExpanded: [],
			attendanceExpanded: [],

			testsItems: [],
			subjectsItems: [],
			behaviorItems: [],
			financeItems: [],
			notesItems: [],
			attendanceItems: [],
			schedule: [],
			messages: [],

			selectedReportMonth: null,
			reportMonths: [],
			reportItems: [],
			reportStatics: null,

			statics: [],
			subjects: [],
			subjectStatics: [],
			selectedSubject: null,
			loadingSubject: false,

			selectedSpecialtyId: null,
			selectedCourseId: null,
			searchName: null,
			loadingStudents: false,
			students: [],

			addNoteBehaviorDialog: false,
			menuOfDate: null,
			notes: {
				TeacherId: null,
				SubjectId: null,
				Date: this.moment().format('YYYY-MM-DD'),
				Content: null,
				StudentId: Number(this.$route.params.id),
				SupervisorId: null
			},
			noteBehaviorId: null,
			submitLoading: false,
			staff: [],
			radioGroup: 0,

			// Manager note
			addNoteDialog: false,
			menuOfDateNoteManager: null,
			notesTypes: [],
			noteManagerId: null,
			AccountIdStudent: null,
			noteManager: {
				AccountId: null,
				NoteTypeId: null,
				Note: null,
				Date: this.moment().format('YYYY-MM-DD')
			},
			deleteNoteManagerDialog: false,
			deleteNoteBehaviorDialog: false,
			newVersion,
			rules
		};
	},
	computed: {
		averages() {
			return this.selectedSubject ? this.subjectStatics.MarksChartResults : this.statics.StaticsAverage;
		},

		testsHeader() {
			const testsHeader = [
				{
					text: 'المادة',
					value: 'Test.SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'محتوى الاختبار',
					value: 'Test.Details',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'نوع الاختبار',
					value: 'Test.TestType',
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					sortable: false,
					showInMobile: true,
				},
				{ text: 'تاريخ', value: 'Test.Date', showInMobile: true },
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},
		attendanceHeader() {
			const attendanceHeader = [
				{
					text: 'التاريخ',
					value: 'date',
					showInMobile: true,
				},
				{
					text: 'النوع',
					value: 'type',
					showInMobile: true,
				},
				{
					text: 'التبرير',
					value: 'Justification',
					sortable: false,
				},
				{
					text: 'المبرر',
					value: 'ParentJustification',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return attendanceHeader.filter((e) => e.showInMobile);
			}
			return attendanceHeader;
		},
		behaviorHeader() {
			const behaviorHeader = [
				{
					text: 'الاستاذ/الموجه',
					value: 'teacher',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'المادة',
					value: 'SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
				},
				{
					text: 'الملاحظة',
					value: 'Content',
					sortable: false,
					sortable: false,
				}
			];
			if (this.$vuetify.breakpoint.mobile) {
				return behaviorHeader.filter((e) => e.showInMobile);
			}
			if(this.newVersion) {
				behaviorHeader.push({
					text: '',
					value: 'action',
					sortable: false,
					sortable: false,
					width: '15%'
				})
			}
			return behaviorHeader;
		},
		notesHeader() {
			const behaviorHeader = [
				{
					text: 'النوع',
					value: 'NoteType',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'الملاحظة',
					value: 'Note',
					sortable: false,
				}
			];
			if (this.$vuetify.breakpoint.mobile) {
				return behaviorHeader.filter((e) => e.showInMobile);
			}
			if(this.newVersion) {
				behaviorHeader.push({
					text: '',
					value: 'action',
					sortable: false,
				})
			}
			return behaviorHeader;
		},
		financeHeader() {
			const financeHeader = [
				{
					text: 'مقبوض',
					value: 'InAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'عليه',
					value: 'OutAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'رقم الدفتر',
					value: 'BookId',
					sortable: false,
				},
				{
					text: 'رقم الوصل',
					value: 'ReceiptId',
					sortable: false,
				},
				{
					text: 'النوع',
					value: 'type',
					sortable: false,
				},
				{
					text: 'تفاصيل',
					value: 'Details',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return financeHeader.filter((e) => e.showInMobile);
			}
			return financeHeader;
		},
		subjectsHeader() {
			const subjectsHeader = [
				{
					width: '50%',
					text: 'المادة',
					value: 'SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					width: '30%',
					text: 'المعدل',
					value: 'SubjectAverage',
					showInMobile: true,
				},
				{
					width: '20%',
					text: 'عدد العلامات الكاملة',
					value: 'MaxMarkCount',
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return subjectsHeader.filter((e) => e.showInMobile);
			}
			return subjectsHeader;
		},
		reportHeader() {
			const reportsHeader = [
				{
					text: 'المادة',
					value: 'Test.SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'محتوى الاختبار',
					value: 'Test.Details',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'نوع الاختبار',
					value: 'Test.TestType',
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					sortable: false,
					showInMobile: true,
				},
				{ text: 'تاريخ', value: 'Test.Date', showInMobile: true },
			];
			if (this.$vuetify.breakpoint.mobile) {
				return reportsHeader.filter((e) => e.showInMobile);
			}
			return reportsHeader;
		},

		studentsHeader() {
			const studentsHeader = [
				{
					text: 'اسم الطالب',
					value: 'name',
					align: 'start',
					showInMobile: true,
				},
				{
					text: 'الصف',
					value: 'specialty',
					align: 'start',
					showInMobile: true,
				},
				{
					text: 'الشعبة',
					value: 'Courses.CourseName',
					align: 'start',
				},
			];
			if (this.$vuetify.breakpoint.mobile) return studentsHeader.filter((e) => e.showInMobile);
			return studentsHeader;
		},

		...mapState({
			specialties: (state) => state.specialties.specialties,
			userName: state => state.auth.user.userName,
			teachers: state => state.teacher.teachersCourse.data,
			subjectsForCourseId: state => state.homeWork.subjects
		}),
	},

	methods: {
		fetchProfile() {
			this.loading = true;
			this.allowProfile = true;
			this.$store
				.dispatch('student/fetchProfile', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.studentProfile = res;
					this.AccountIdStudent = this.studentProfile.AccountId
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowProfile = false;
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchTests() {
			this.loadingTabs = true;
			this.allowTests = true;
			this.$store
				.dispatch('student/fetchTests', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.testsItems = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowTests = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSubjects() {
			this.loadingTestsSubjects = true;
			this.allowSubjects = true;
			this.$store
				.dispatch('courses/fetchSubjects', {
					courseId: this.studentProfile.CourseId,
				})
				.then((res) => {
					this.subjectsItems = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowSubjects = false;
					}
				})
				.finally(() => {
					this.loadingTestsSubjects = false;
				});
		},
		// for note behavior
		fetchStaff() {
			this.$store.dispatch('managerCourses/fetchStaff', { courseId: this.studentProfile.CourseId }).then((data) => {
				this.staff = data
			})
		},
		fetchNotes() {
			this.loadingTabs = true;
			this.allowBehavior = true;
			Promise.all([
				this.fetchTeachers(),
				this.fetchSubjectsAll(),
				this.fetchStaff()
			]).then(() => {
				this.$store
					.dispatch('student/fetchNotes', {
						studentId: this.$route.params.id,
					})
					.then((res) => {
						this.behaviorItems = res;
					})
					.catch((err) => {
						if (err?.response?.status === 405) {
							this.allowBehavior = false;
						}
					})
					.finally(() => {
						this.loadingTabs = false;
					});
			})
		},
		fetchSubjectsAll () {
			this.$store.dispatch('homeWork/getSubject', { 
				courseId: this.studentProfile.CourseId
			})
		},
		fetchTeachers () {
			this.$store.dispatch('teacher/fetchAllCourse', {  
				courseId: this.studentProfile.CourseId 
			})
		},
		createOrUpdateNoteBe () {
			if(this.$refs.fromBehavior.validate()) {
				this.submitLoading = true
				const notesId = { ...this.notes, Id: this.noteBehaviorId}
				this.$store.dispatch('managerCourses/createOrUpdateBehavioralNotes', { 
					info: notesId,
					courseId: this.studentProfile.CourseId
				}).then(() => {
					this.fetchNotes()
					this.addNoteBehaviorDialog = false
				}).finally(() => {
					this.submitLoading = false
				})
			}
		},
		deleteBehaviorNote () {
			this.submitLoading = true
			this.$store.dispatch('managerCourses/deleteBehavioralNotes', { 
				courseId: this.studentProfile.CourseId,
				noteId: this.noteBehaviorId
			}).then(() => {
				this.fetchNotes()
				this.deleteNoteBehaviorDialog = false
			}).finally(() => {
				this.submitLoading = false
				this.noteBehaviorId = null
			})
		}, 
		// -- note manager
		fetchManagerNotes() {
			this.loadingTabs = true;
			this.allowNotes = true;
			this.$store
				.dispatch('student/fetchManagerNotes', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.notesItems = res;
					this.$store.dispatch('managerCourses/fetchNoteType').then((data) => {
						this.notesTypes = data
					})
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowNotes = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		deleteManagerNote() {
			this.submitLoading = true
			this.$store.dispatch('managerCourses/deleteManagerNotes', { noteId: this.noteManagerId }).then(() => {
				this.fetchManagerNotes()
				this.deleteNoteManagerDialog = false
			}).finally(() => {
				this.submitLoading =  false
				this.noteManagerId = null
			})
		},
		// ---
		fetchAttendance() {
			this.loadingTabs = true;
			this.allowAttendance = true;
			this.$store
				.dispatch('student/fetchAttendance', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.attendanceItems = [...res.AbsenceList, ...res.EarlyLeaveList, ...res.LateList].sort(
						(a, b) => {
							return this.moment(a.Date).isAfter(b.Date) ? -1 : 1;
						},
					);
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowAttendance = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchFunds() {
			this.loadingTabs = true;
			this.allowFinance = true;
			this.$store
				.dispatch('student/fetchFunds', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.financeItems = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowFinance = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSchedule() {
			this.loadingTabs = true;
			this.allowSchedule = true;
			this.$store
				.dispatch('student/fetchSchedule', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.schedule = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowSchedule = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchMessages() {
			this.loadingTabs = true;
			this.allowChat = true;
			this.$store
				.dispatch('student/fetchMessages', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.messages = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowChat = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchStaticsAndSubjects() {
			this.loadingTabs = true;
			this.allowSubjects = true;
			Promise.all([
				this.$store
					.dispatch('student/fetchStatics', {
						studentId: this.$route.params.id,
					})
					.then((res) => {
						this.statics = res;
					}),
				this.$store
					.dispatch('student/fetchAllSubjects', {
						studentId: this.$route.params.id,
					})
					.then((res) => {
						this.subjects = res;
					}),
			])
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowSubjects = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchMonthsForReports() {
			this.loadingTabs = true;
			this.allowReport = true;
			this.$store
				.dispatch('student/fetchStatics', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.reportMonths = res?.CourseMonths;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowReport = false;
					}
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchReport() {
			this.loadingReport = true;
			this.allowReport = true;
			this.$store
				.dispatch('student/fetchReport', {
					studentId: this.$route.params.id,
					month: this.selectedReportMonth,
				})
				.then((res) => {
					this.reportItems = res?.Marks;
					this.reportStatics = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowReport = false;
					}
				})
				.finally(() => {
					this.loadingReport = false;
				});
		},

		fetchSubjectStatics(item) {
			if (item.all) {
				this.selectedSubject = null;
				this.loadingSubject = true;
				setTimeout(() => {
					this.loadingSubject = false;
				}, 500);
				return;
			}
			this.selectedSubject = item.SubjectName;
			this.loadingSubject = true;
			this.allowSubjects = true;
			this.$store
				.dispatch('student/fetchSubjectStatics', {
					studentId: this.$route.params.id,
					subjectId: item.SubjectId,
				})
				.then((res) => {
					this.subjectStatics = res;
				})
				.catch((err) => {
					if (err?.response?.status === 405) {
						this.allowSubjects = false;
					}
				})
				.finally(() => {
					this.loadingSubject = false;
				});
		},

		filteredTests() {
			this.loadingTests = true;
			setTimeout(() => {
				this.loadingTests = false;

				this.testsArr = this.testsItems
					.filter((e) => {
						return this.testSubjectId ? e.Test.SubjectId === this.testSubjectId : true;
					})
					.filter((e) => {
						return this.testDetails ? e.Test.Details.includes(this.testDetails) : true;
					});
			}, 500);
		},

		// for search
		search() {
			if (this.selectedCourseId) this.fetchStudentByCourse();
			else if (this.searchName) this.fetchStudentsByName();
			else {
				this.selectedCourseId = null;
				this.selectedSpecialtyId = null;
				this.searchName = null;
				this.searched = false;
			}
		},
		fetchStudentByCourse() {
			this.loadingStudents = true;
			this.$store
				.dispatch('student/fetchStudentsByCourse', {
					courseId: this.selectedCourseId,
				})
				.then((res) => {
					this.students = res;
					if (this.searchName) {
						this.students = this.students.filter((e) => {
							return (
								e.Student.Account.FirstName.includes(this.searchName) ||
								e.Student.Account.LastName.includes(this.searchName)
							);
						});
					}
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		fetchStudentsByName() {
			this.loadingStudents = true;
			this.$store
				.dispatch('student/fetchStudentsByName', {
					studentName: this.searchName,
				})
				.then((res) => {
					this.students = res;
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		fetchSpecialties() {
			this.loadingStudents = true;
			this.$store.dispatch('specialties/fetchAll').finally(() => {
				this.loadingStudents = false;
			});
		},
		changeStudent(item) {
			this.$router.replace({ params: { id: item.Student.Id } });
			this.fetchProfile();
			this.tab = 'profile';
			this.dialog = false;
		},

		// style methods
		showBehaviorExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.behaviorExpanded.findIndex((i) => i === item);
				this.behaviorExpanded.splice(indexExpanded, 1);
			} else {
				this.behaviorExpanded.push(item);
			}
		},
		showNotesExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.notesExpanded.findIndex((i) => i === item);
				this.notesExpanded.splice(indexExpanded, 1);
			} else {
				this.notesExpanded.push(item);
			}
		},
		showFinanceExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.financeExpanded.findIndex((i) => i === item);
				this.financeExpanded.splice(indexExpanded, 1);
			} else {
				this.financeExpanded.push(item);
			}
		},
		showAttendanceExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.attendanceExpanded.findIndex((i) => i === item);
				this.attendanceExpanded.splice(indexExpanded, 1);
			} else {
				this.attendanceExpanded.push(item);
			}
		},
		saveDate (date) {
            this.$refs.menuOfDate.save(date);
        },
		createOrUpdateNoteManager() {
			if(this.$refs.formManager.validate()) {
				this.submitLoading = true
				const notesId = { ...this.noteManager, Id: this.noteManagerId}
				this.$store.dispatch('managerCourses/createOrUpdateManagerNotes', { 
					info: notesId
				}).then(() => {
					this.fetchManagerNotes()
					this.addNoteDialog = false
				}).finally(() => {
					this.submitLoading = false
				})
			}
		},
		moment,
	},

	created() {
		this.fetchProfile();
		this.fetchSpecialties();

		if (this.$route.query.courseId && this.$route.query.specialtyId) {
			this.selectedCourseId = Number(this.$route.query.courseId);
			this.selectedSpecialtyId = Number(this.$route.query.specialtyId);
			this.fetchStudentByCourse();
		}
	},

	metaInfo: {
		title: 'Student',
		titleTemplate: '%s | Edunix',
	},
};
</script>
<style scoped lang="scss"></style>
