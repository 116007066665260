export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const CENTERS = "CENTERS"
export const ICON = "ICON"

export const SPECIALTIES = "SPECIALTIES"

export const STUDENTS = "STUDENTS"
export const STUDENT_PROFILE = "STUDENT_PROFILE"

export const TEACHERS = 'TEACHERS'
export const DEGREES = 'DEGREES'

export const ALL_STUDENTS = 'ALL_STUDENTS'

export const STORE_SUBJECTS = 'STORE_SUBJECTS'
export const STORE_HOMEWORK = 'STORE_HOMEWORK'
export const TEACHERS_COURSE = 'TEACHERS_COURSE'
export const STUDENT_IN_COURSE = 'STUDENT_IN_COURSE'
