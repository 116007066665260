import * as types from '../mutations';
export default {
    namespaced: true,

	state: {
	    homeworks: [],
        subjects: []        
	},

    actions: {
        // .get(rootState.auth.url + '/get-teachers')
        getAll ({ rootState, commit }, { courseId, fromDate, toDate }) {
            return window.axios.get(rootState.auth.url +'/get-homework', { 
                params: { 
                    courseId, 
                    fromDate, 
                    toDate 
                } 
            }).then(({ data }) => {
                commit(types.STORE_HOMEWORK, data)
                return data
            })
        },

        createOrUpdate ({ rootState, commit }, { homeWorkRequest }) {
            return window.axios.post(rootState.auth.url +'/add-update-homework',  homeWorkRequest).then(({ data }) => {
                return data
            })
        },

        delete ({ rootState, commit }, { courseId, homeWorkId }) {
            return window.axios.post(rootState.auth.url +'/delete-homework', {}, { 
                params: { homeWorkId, courseId } 
            })
        },

        getSubject ({ rootState, commit }, { courseId }) {
            return window.axios.get(rootState.auth.url +'/get-subjects', { 
                params: {
                    courseId
                } 
            }).then((data) => {
                commit(types.STORE_SUBJECTS, data.data)
                return data.data
            })
        }
    },

    mutations: {
        [types.STORE_SUBJECTS] (state, subjects) {
            state.subjects = subjects
        },

        [types.STORE_HOMEWORK] (state, homeworks) {
            state.homeworks = homeworks
        }
    },

    getters: {
        getSubjectById: state => id => state.subjects.find(c => c.Id === id),
        getHomeworkById: state => id => state.homeworks.find(c => c.Id === id)
    }
};

