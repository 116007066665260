import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import $eventBus from '../helpers/eventBus';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

let firstEnter = true;
router.beforeEach(async (to, from, next) => {
	// first time
	if (firstEnter) {
		firstEnter = false;
		const user = JSON.parse(localStorage.getItem('auth'));
		if (user) {
			await store
				.dispatch('auth/login', {
					userName: user?.userName,
					password: user?.password,
					centerId: user?.centerId,
				})
				.finally(() => {
					$eventBus.$emit('loader', false);
				});
		} else if (to.name !== 'login') {
			next({ name: 'login' });
			return;
		}
	}

	// the page in public
	if (!to.meta.permissionTab) {
		next();
		return;
	}

	const permission = store.state.auth.permissions;

	let allowed = true;
	to.meta.permissionTab.forEach((e) => {
		allowed &= permission[e];
	});
	if (allowed) {
		next();
	}
});

export default router;
